import { Box, Button, Container, Grid, Typography, styled, TextField, FormControl, InputLabel, Select, MenuItem, Divider, Avatar, Drawer, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, NativeSelect, InputBase, makeStyles, ButtonGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
let profileImage = require('./image_profile1.png')
let Image = require('./Edit_1.png')
const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
    },
});
const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "1px solid black",
        width: '100%'
    },
    selectEmpty: {
        marginTop: '0px',
    },
}));


const Screen1 = ({ profileImageEdit, profileimage1, handleButtonClick, selected, handleChangeSelect, state, classes, UpDateChildProfile, open2, onNext, DrawerClose, handleProfileImageChange, image, handleChange, formData }: any) => (
    <Container>
        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '50px 20px 15px' }}>
            <Typography style={{ fontSize: '32px', fontWeight: 500 }}>Enroll Child</Typography>
            <CloseIcon onClick={DrawerClose} style={{ color: '000000', cursor: 'pointer' }} />
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            {profileimage1 ? (
                <Avatar
                    src={profileimage1}
                    style={{
                        width: '130px',
                        height: '130px',
                        marginRight: '20px',
                    }}
                />
            ) : (
                <AccountCircleIcon
                    style={{
                        color: '#E5E5E5',
                        width: '130px',
                        height: '130px',
                        paddingRight: '20px',
                    }}
                />
            )}

            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-profile-picture"
                onChange={handleProfileImageChange}
            />

            <label htmlFor="upload-profile-picture">
                <Button
                    component="span"
                    style={{
                        border: '1px solid #00000',
                        borderRadius: '10px',
                    }}
                >
                    Edit profile picture
                </Button>
            </label>
        </Box>
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Child Information</Typography>
            <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} variant="outlined" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} variant="outlined" />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <ButtonGroup
                                orientation="vertical"
                                color="primary"
                                aria-label="vertical outlined primary button group"
                                style={{ width: "100%" }}
                            >
                                <Button
                                    onClick={() => handleButtonClick("Male")}
                                    style={{
                                        height: "60px",
                                        width: "100%",
                                        borderRadius: '15px',
                                        border: '1px solid #C4C4C4',
                                        backgroundColor: selected === "Male" ? "#3DB3E4" : "white",
                                        color: selected === "Male" ? "white" : "black",
                                    }}
                                >
                                    Male
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <ButtonGroup
                                orientation="vertical"
                                color="primary"
                                aria-label="vertical outlined primary button group"
                                style={{ width: "100%" }}
                            >
                                <Button
                                    onClick={() => handleButtonClick("Female")}
                                    style={{
                                        borderRadius: '15px',
                                        height: "60px",
                                        width: "100%",
                                        border: '1px solid #C4C4C4',
                                        backgroundColor: selected === "Female" ? "#3DB3E4" : "white",
                                        color: selected === "Female" ? "white" : "black",
                                    }}
                                >
                                    Female
                                </Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <ButtonGroup
                                orientation="vertical"
                                color="primary"
                                aria-label="vertical outlined primary button group"
                                style={{ width: "100%" }}
                            >
                                <Button
                                    onClick={() => handleButtonClick("Not Specified")}
                                    style={{
                                        borderRadius: '15px',
                                        border: '1px solid #C4C4C4',
                                        height: "60px",
                                        width: "100%",
                                        backgroundColor: selected === "Not Specified" ? "#3DB3E4" : "white",
                                        color: selected === "Not Specified" ? "white" : "black",
                                    }}
                                >
                                    Not Specified
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Birthday"
                            value={formData.birthday ? moment(formData.birthday).toDate() : null}
                            onChange={(value) => {
                                handleChange({
                                    target: { name: 'birthday', value: value }
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Box>
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Address</Typography>
            <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="addressLine1" value={formData.addressLine1} onChange={handleChange} placeholder="Address line 1" variant="outlined" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="addressLine2" value={formData.addressLine2} onChange={handleChange} placeholder="Address line 2" variant="outlined" />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="apt" value={formData.apt} onChange={handleChange} placeholder="Apt#" variant="outlined" />
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="city" value={formData.city} onChange={handleChange} placeholder="City" variant="outlined" />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <CustomTextField style={Styles.textField1} value={formData.state} name="state" onChange={handleChange} id="outlined-basic" placeholder="State" variant="outlined" />
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="zipcode" value={formData.zipcode} onChange={handleChange} placeholder="Zipcode" variant="outlined" />
                </Grid>
            </Grid>
        </Box>
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Child Notes</Typography>
            <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="legalCustody" value={formData.legalCustody} onChange={handleChange} placeholder="Legal Custody" variant="outlined" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" name="language" value={formData.language} onChange={handleChange} placeholder="English" variant="outlined" />
                </Grid>
            </Grid>
        </Box>
        <Button onClick={() => open2 ? UpDateChildProfile(formData, image) : onNext()} style={{ width: '180px', height: '50px', backgroundColor: 'F1544B', color: '#fff', fontSize: '16px', fontWeight: 600, borderRadius: '12px' }}>{open2 ? 'Save Changes' : 'Next'}</Button>
    </Container>
);

const Screen2 = ({ duration, setDuration, startTime, setStartTime, isDisabled, toggleIcon5, activeIcons5, activeIcons3, toggleIconNew, image2, handleNotesChange, notes, toggleIcon2, activeIcons2, toggleIcon1, activeIcons1, toggleIcon, activeIcons, postPottyTrainingData, imageSelect, handleBoxClick, onNext, onBack, imageArray, imageArray1, imageArray2, imageArray3, preview, handleImageChange, DrawerClose }: any) => (
    <Container>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 20px 15px' }}>
            <ArrowBackIosIcon onClick={onBack} />
            <Typography style={{ fontSize: '32px', fontWeight: 500 }}>Additional Info</Typography>
            <CloseIcon onClick={DrawerClose} style={{ color: '000000' }} />
        </Box>
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Allergen</Typography>
            <Grid container spacing={4}>
                {imageArray.map((item: any, index: number) => {
                    const isActive = activeIcons3.includes(item.id)
                    return (
                        <Grid key={index} item lg={2} md={3} sm={4} xs={4}>
                            <Box onClick={() => toggleIconNew(item)}
                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }}
                            >
                                <img
                                    style={{
                                        width: '60px',
                                        height: '60px',
                                        borderRadius: '8px',
                                    }}
                                    src={
                                        isActive
                                            ? item.attributes.active_icon
                                            : item.attributes.inactive_icon
                                    }
                                    alt="Icon"
                                />
                                <Typography>{item.attributes.name}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Potty Training</Typography>
            <Grid container spacing={4}>
                {imageArray1.map((item: any, index: number) => {
                    const isActive1 = activeIcons1.includes(item.id);
                    return (
                        <Grid key={index} item lg={2} md={3} sm={4} xs={4}>

                            <Box
                                onClick={() => toggleIcon1(item)}
                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img
                                    style={{ width: "60px", height: "60px" }}
                                    src={
                                        isActive1
                                            ? item.attributes.active_icon
                                            : item.attributes.inactive_icon
                                    }
                                />
                                <Typography style={{ textAlign: 'center' }}>{item.attributes.name}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Naps</Typography>
            <Grid container spacing={4}>
                {imageArray2.map((item: any, index: number) => {
                    const isActive2 = activeIcons2.includes(item.id);
                    return (
                        <Grid key={index} item lg={2} md={3} sm={4} xs={4}>
                            <Box onClick={() => toggleIcon2(item)} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img
                                    style={{ width: "60px", height: "60px" }}
                                    src={
                                        isActive2
                                            ? item.attributes.active_icon
                                            : item.attributes.inactive_icon
                                    }
                                />
                                <Typography style={{ textAlign: 'center' }}>{item.attributes.name}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
                <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 10px' }}>Typical Start Time</Typography>
                        <CustomTextField disabled={isDisabled} value={startTime}
                            onChange={(e) => setStartTime(e.target.value)} style={Styles.textField1} id="outlined-basic" placeholder="Enter Time" variant="outlined" />
                    </Box>
                </Grid>
                <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 10px' }}>Typical Duration</Typography>
                        <CustomTextField disabled={isDisabled} value={duration}
                            onChange={(e) => setDuration(e.target.value)} style={Styles.textField1} id="outlined-basic" placeholder="Enter duration" variant="outlined" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Solid and Purees</Typography>
            <Grid container spacing={4}>
                {imageArray3.map((item: any, index: number) => {
                    const isActive1 = activeIcons5.includes(item.id);
                    return (
                        <Grid key={index} item lg={2} md={3} sm={4} xs={4}>
                            <Box
                                onClick={() => toggleIcon5(item)}
                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img
                                    style={{ width: "60px", height: "60px" }}
                                    src={
                                        isActive1
                                            ? item.attributes.active_icon
                                            : item.attributes.inactive_icon
                                    }
                                />
                                <Typography style={{ textAlign: 'center' }}>{item.attributes.name}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Special Notes</Typography>
            <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <CustomTextField style={Styles.textField2} id="outlined-basic" value={notes}
                            onChange={handleNotesChange} placeholder="Enter Notes" variant="outlined" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Upload Vaccination Card</Typography>
            <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box style={Styles.textField2}>
                        {preview ? (
                            <img src={preview} alt="Preview" style={Styles.previewImage} />
                        ) : (
                            <label htmlFor="fileInput" style={Styles.plusIcon}>
                                <AddIcon style={{ fontSize: 40, cursor: 'pointer', paddingRight: '5px', color: '#3DB3E4' }} />
                                <Typography style={{ color: '#3DB3E4' }}>Upload Image</Typography>
                            </label>
                        )}
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Button onClick={onNext} style={{ width: '180px', height: '50px', backgroundColor: 'F1544B', color: '#fff', fontSize: '16px', fontWeight: 600, borderRadius: '12px' }}>Next</Button>
    </Container >
);

const Screen3 = ({ SaveChanges, handleClickOpenDialog1, handleClickOpenDialog, toggleDrawer2, DrawerClose, handleClickOpen }: any) => (
    <Container>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 20px 15px' }}>
            <Typography style={{ fontSize: '32px', fontWeight: 500, display: 'flex', alignItems: 'center' }}>Child Profile <Box style={{ marginLeft: '20px' }} onClick={() => handleClickOpen(toggleDrawer2)}><Typography style={{ color: 'F1544B', fontSize: '18px', fontWeight: 600 }}>Edit <img src={Image} /></Typography></Box></Typography>
            <CloseIcon onClick={DrawerClose} style={{ color: '000000' }} />
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
            <img src={toggleDrawer2.attributes?.child_profile_image && toggleDrawer2.attributes.child_profile_image.length > 0 ? toggleDrawer2?.attributes?.child_profile_image : profileImage} style={{
                width: '100px',
                height: '100px',
                paddingRight: '20px'
            }} />
            <Box>
                <Typography style={{ fontSize: '24px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.first_name}</Typography>
                <Typography style={{ fontSize: '17px', fontWeight: 500 }}>{toggleDrawer2?.attributes?.age} years old</Typography>
                <Typography style={{ fontSize: '19px', fontWeight: 500 }}>ID: #{toggleDrawer2?.id}</Typography>
            </Box>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Personal Information</Typography>
            <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>First Name</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.first_name}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Last Name</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.first_name}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Date of Birth</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.date_of_birth}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Gender</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.gender}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Address</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.address1}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Apt</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.apt}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>City</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.city}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>State</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.state}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Zipcode</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.zip_code}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Legal Custody</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.legal_custody}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Language</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.language}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Divider />
        <Box style={{ margin: '20px 0px' }}>
            <Grid container spacing={2}>

                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography onClick={() => handleClickOpenDialog("Allergens")} style={{ cursor: 'pointer', margin: '0px 0px 0px', fontSize: '17px' }}>Allergens <img style={{ width: "20px", height: "20px" }} src={Image} /></Typography>
                        {toggleDrawer2?.attributes?.allergens.map((item: any) => {
                            return (
                                <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{item?.allergens_name}</Typography>
                            )
                        })}
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography onClick={() => handleClickOpenDialog("Potty Training")} style={{ cursor: 'pointer', margin: '0px 0px 0px', fontSize: '17px' }}>Potty Training <img style={{ width: "20px", height: "20px" }} src={Image} /></Typography>

                        {toggleDrawer2?.attributes?.potty_trainings?.length > 0 ? (
                            toggleDrawer2.attributes.potty_trainings.map((item: any, index: number) => (
                                <Typography
                                    key={index}
                                    style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}
                                >
                                    {item?.potty_trainings_name ? item?.potty_trainings_name : "None"}
                                </Typography>
                            ))
                        ) : (
                            <Typography
                                style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}
                            >
                                None
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography onClick={() => handleClickOpenDialog("Naps")} style={{ cursor: 'pointer', margin: '0px 0px 0px', fontSize: '17px' }}>Naps <img style={{ width: "20px", height: "20px" }} src={Image} /></Typography>
                        {toggleDrawer2?.attributes?.nap_time ? (
                            <>
                                <Typography
                                    style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}
                                >
                                    Start Time: {toggleDrawer2?.attributes?.nap_time?.nap_start_time ? toggleDrawer2?.attributes?.nap_time?.nap_start_time : "None"}
                                </Typography>
                                <Typography
                                    style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}
                                >
                                    Duration: {toggleDrawer2?.attributes?.nap_time?.nap_duration ? toggleDrawer2?.attributes?.nap_time?.nap_duration : "None"}
                                </Typography>
                            </>
                        ) : (
                            <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>None</Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography onClick={() => handleClickOpenDialog("Solid")} style={{ cursor: 'pointer', margin: '0px 0px 0px', fontSize: '17px' }}>Solid and Purees <img style={{ width: "20px", height: "20px" }} src={Image} /></Typography>
                        {toggleDrawer2?.attributes?.solid_and_purees?.length > 0 ? (
                            toggleDrawer2.attributes.solid_and_purees.map((item: any, index: number) => (
                                <Typography
                                    key={index}
                                    style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}
                                >
                                    {item?.solid_and_purees_name ? item?.solid_and_purees_name : "None"}
                                </Typography>
                            ))
                        ) : (
                            <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>None</Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Special Notes</Typography>
                        <Typography style={{ margin: '0px 0px 10px', fontSize: '19px', fontWeight: 600 }}>{toggleDrawer2?.attributes?.special_note?.special_note_description}</Typography>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ margin: '0px 0px 0px', fontSize: '17px' }}>Vaccination Card</Typography>
                        <Typography
                            style={{
                                borderRadius: '10px',
                                border: '1px solid #3DB3E4',
                                margin: '10px 0px 10px',
                                fontSize: '19px',
                                fontWeight: 600,
                                padding: '10px',
                                cursor: toggleDrawer2?.attributes?.vaccination_upload_image ? 'pointer' : 'not-allowed',
                                color: toggleDrawer2?.attributes?.vaccination_upload_image ? 'inherit' : 'gray',
                            }}
                            onClick={
                                toggleDrawer2?.attributes?.vaccination_upload_image
                                    ? () => handleClickOpenDialog1(toggleDrawer2?.attributes?.vaccination_upload_image)
                                    : undefined
                            }
                        >
                            {toggleDrawer2?.attributes?.vaccination_upload_image
                                ? `Please Check Vaccination to click`
                                : "Empty"}
                        </Typography>

                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Button onClick={() => SaveChanges()} style={{ width: '180px', height: '50px', backgroundColor: 'F1544B', color: '#fff', fontSize: '16px', fontWeight: 600, borderRadius: '12px' }}>Save Changes</Button>
    </Container >
);

const Screen4 = ({ onBack, DrawerClose }: any) => (
    <Container>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 20px 15px' }}>
            <ArrowBackIosIcon onClick={onBack} />
            <Typography style={{ fontSize: '32px', fontWeight: 500 }}>Subscription Confirmation</Typography>
            <CloseIcon onClick={DrawerClose} style={{ color: '000000' }} />
        </Box>
        <Box style={{ margin: '30px auto', display: 'flex', justifyContent: 'center' }}>
            <img width="200px" height="200px" src={profileImage} />
        </Box>
        <Box style={{ margin: '10px auto', width: '45%', textAlign: 'center' }}>
            <Typography style={{ fontSize: '30px', fontWeight: 600 }}>
                Mike Roberts
            </Typography>
        </Box>
        <Box style={{ margin: '10px auto', width: '45%', textAlign: 'center' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 600, color: '#F1544B' }}>
                $150/month
            </Typography>
        </Box>
        <Box style={{ margin: '10px auto', width: '60%', textAlign: 'center' }}>
            <Typography style={{ fontSize: '15px' }}>
                Enroll your child today for uninterrupted access to our premium childcare services.
            </Typography>
        </Box>
        <Box style={{ margin: '40px auto', width: '60%' }}>
            <Typography style={{ fontSize: '13px' }}>Your subscription to Recess will be charged monthly to your selected payment method automatically.
                To cancel, please use the app store platform where you subscribed. Recess cannot process cancellations directly.
                For billing assistance, contact the app store's customer support.
            </Typography>
        </Box>
        <Box style={{ margin: '40px auto', width: '60%' }}>
            <Button style={{ width: '100%', height: '50px', backgroundColor: 'F1544B', color: '#fff', fontSize: '16px', fontWeight: 600, borderRadius: '12px', textTransform: 'capitalize' }}>Enroll</Button>
        </Box>
    </Container>
)


function AddChild({ getChildProfileData, postVaccinationUploadData, postChildSolidPureesData, postNapsTimingData, profileImageEdit, selected, handleButtonClick, getAllSolidandPureesData, getAllNapsData, getPottyTrainingData, get_allergenProfileData, postNotesgData, UpDateChildProfile, handleFormChange2, toggleDrawer2, open2, postPottyTrainingData, imageSelect, handleBoxClick, postEnrollChildData, handleChange, formData, imageArray, imageArray1, imageArray2, imageArray3, DrawerClose }: any) {
    const [currentScreen, setCurrentScreen] = useState(1);
    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [preview, setPreview] = useState(null);
    const [profileimage, setProfileImage] = useState<any>(null);
    const [profileimage1, setProfileImage1] = useState<any>(null);
    const [open3, setOpen3] = React.useState(false);
    const [activeIcons, setActiveIcons] = useState<number[]>([]);
    const [activeIcons1, setActiveIcons1] = useState<number[]>([]);
    const [activeIcons2, setActiveIcons2] = useState<number[]>([]);
    const [activeIcons3, setActiveIcons3] = useState<number[]>([]);
    const [activeIcons5, setActiveIcons5] = useState<number[]>([]);
    const [notes, setNotes] = useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog1, setOpenDialog1] = React.useState(false);
    const [imageArrayData, setImageArrayData] = useState([]);
    const [iconName, setIconName] = useState('');
    const classes = useStyles();
    const [allergen, setAllergen] = useState([]);
    const [training, setTraining] = useState([]);
    const [solidPurees, setSolidPurees] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [startTime, setStartTime] = useState("");
    const [duration, setDuration] = useState("");
    const [cardImage, setCardImage] = useState("");
    const [state, setState] = React.useState({
        age: '',
    });
    const [state1, setState1] = React.useState({
        age: '',
    });

    const handleChangeSelect = (event: any) => {
        setState({
            ...state,
            age: event.target.value,
        });
    };

    console.log(toggleDrawer2, "toggleDrawer2");


    const handleClickOpenDialog = (name: string) => {
        setOpenDialog(true);
        if (name === "Allergens") {
            get_allergenProfileData()
            setImageArrayData(imageArray)
            setIconName('Allergens')
        } else if (name === "Potty Training") {
            getPottyTrainingData()
            setImageArrayData(imageArray1)
            setIconName('Potty Training')
        } else if (name === "Naps") {
            getAllNapsData()
            setImageArrayData(imageArray2)
            setIconName('Naps')
        } else if (name === "Solid") {
            getAllSolidandPureesData()
            setImageArrayData(imageArray3)
            setIconName('Solid and Purees')
        }
    };

    const handleCloseDialog1 = () => {
        setOpenDialog1(false);
        setActiveIcons([])
    };

    const handleClickOpenDialog1 = (name: string) => {
        setOpenDialog1(true);
        setCardImage(name)
    };


    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
    };

    const toggleIcon = (item: any) => {
        setActiveIcons((prevActiveIcons) => {
            if (item.attributes.name === "None") {
                return prevActiveIcons.includes(item.id) ? [] : [item.id];
            } else {
                if (prevActiveIcons.includes(item.id)) {
                    return prevActiveIcons.filter((iconId) => iconId !== item.id);
                } else {
                    return prevActiveIcons
                        //@ts-ignore
                        .filter((iconId) => imageArrayData.find((icon) => icon.id === iconId)?.attributes.name !== "None")
                        .concat(item.id);
                }
            }
        });
    };

    const toggleIconNew = (item: any) => {
        setActiveIcons3((prevActiveIcons) => {
            let updatedIcons: any;

            if (item.attributes.name === "None") {
                updatedIcons = prevActiveIcons.includes(item.id) ? [] : [item.id];
            } else {
                const noneId: any = "2";
                if (prevActiveIcons.includes(noneId)) {
                    updatedIcons = [item.id];
                } else {
                    updatedIcons = prevActiveIcons.includes(item.id)
                        ? prevActiveIcons.filter((id: any) => id !== item.id)
                        : [...prevActiveIcons, item.id];
                }
            }
            setAllergen(updatedIcons);
            return updatedIcons;
        });
    };

    const toggleIcon1 = (item: any) => {
        setActiveIcons1(() => {
            const updatedIcons: any = [item.id];
            setTraining(updatedIcons);
            return updatedIcons;
        });
    };

    const toggleIcon2 = (item: any) => {
        setActiveIcons2((prevActiveIcons) => {
            let updatedIcons;
            if (item.attributes.name === "None") {
                updatedIcons = prevActiveIcons.includes(item.id) ? [] : [item.id];
                setIsDisabled(true);
            } else {
                updatedIcons = prevActiveIcons.includes(item.id)
                    ? prevActiveIcons.filter((iconId) => iconId !== item.id)
                    : prevActiveIcons
                        .filter((iconId) => imageArray2.find((icon: any) => icon.id === iconId)?.attributes.name !== "None")
                        .concat(item.id);
                setIsDisabled(false);
            }
            return updatedIcons;
        });
    };

    const toggleIcon5 = (item: any) => {
        setActiveIcons5(() => {
            const updatedIcons: any = [item.id];
            setSolidPurees(updatedIcons);
            return updatedIcons;
        });
    };






    const handleClickOpen = (toggleDrawer2: any) => {
        setOpen3(true);
        setCurrentScreen(1)
        handleFormChange2(toggleDrawer2)
        setProfileImage1(profileImageEdit)
    };

    const handleClose = () => {
        setOpen3(false);
    };


    useEffect(() => {
        if (open2 === 3) {
            setCurrentScreen(3)
        }
    }, [toggleDrawer2])

    const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file1 = event.target.files?.[0];
        setProfileImage(file1)
        const file = event.target.files?.[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setProfileImage1(reader.result);
            };
            reader.readAsDataURL(file);
        }

    };



    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        setImage(event.target.files[0]);

        const reader: any = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const SaveChanges = () => {
        setCurrentScreen(4)
    }


    const handleNext = () => {
        setCurrentScreen((prev) => prev + 1);
        if (currentScreen === 1) {
            const finalData = {
                ...formData,
                profileImage: profileimage,
            };
            postEnrollChildData(finalData);
        } else if (currentScreen === 2) {
            handleBoxClick(allergen)
            postNotesgData(notes)
            postPottyTrainingData(training)
            postNapsTimingData(startTime, duration)
            postChildSolidPureesData(solidPurees)
            postVaccinationUploadData(image)
            DrawerClose()
        }
    };

    const handleBack = () => {
        setCurrentScreen((prev) => Math.max(prev - 1, 1));
    };

    const toggleIconModal = (item: any) => {
        if (iconName === "Allergens") {
            toggleIconNew(item)
        } else if (iconName === "Potty Training") {
            toggleIcon1(item)
        } else if (iconName === "Naps") {
            toggleIcon2(item)
        } else if (iconName === "Solid and Purees") {
            toggleIcon5(item)
        }
    }

    const handleCloseDialog = () => {
        setActiveIcons([])
        if (iconName === "Allergens") {
            handleBoxClick(allergen)
            getChildProfileData()
            DrawerClose()
        } else if (iconName === "Potty Training") {
            postPottyTrainingData(training)
            getChildProfileData()
            DrawerClose()
        } else if (iconName === "Naps") {
            postNapsTimingData(startTime, duration)
            getChildProfileData()
            DrawerClose()
        } else if (iconName === "Solid and Purees") {
            postChildSolidPureesData(solidPurees)
            getChildProfileData()
            DrawerClose()
        }
        setOpenDialog(false);
    };

    return (
        <Box style={{ padding: '0px 25px 25px 25px' }}>
            <Box>
                {currentScreen === 1 && <Screen1 profileImageEdit={profileImageEdit} profileimage1={profileimage1} handleButtonClick={handleButtonClick} selected={selected} handleChangeSelect={handleChangeSelect} state={state} classes={classes} UpDateChildProfile={UpDateChildProfile} open2={open3} handleChange={handleChange} formData={formData} onNext={handleNext} handleProfileImageChange={handleProfileImageChange} image={profileimage} DrawerClose={DrawerClose} />}
                {currentScreen === 2 && <Screen2 duration={duration} setDuration={setDuration} startTime={startTime} setStartTime={setStartTime} isDisabled={isDisabled} activeIcons5={activeIcons5} toggleIcon5={toggleIcon5} toggleIconNew={toggleIconNew} image2={image2} notes={notes} handleNotesChange={handleNotesChange} toggleIcon2={toggleIcon2} activeIcons3={activeIcons3} activeIcons2={activeIcons2} toggleIcon1={toggleIcon1} activeIcons1={activeIcons1} toggleIcon={toggleIcon} activeIcons={activeIcons} postPottyTrainingData={postPottyTrainingData} imageSelect={imageSelect} handleBoxClick={handleBoxClick} imageArray={imageArray} imageArray2={imageArray2} imageArray1={imageArray1} imageArray3={imageArray3} onNext={handleNext} onBack={handleBack} preview={preview} handleImageChange={handleImageChange} DrawerClose={DrawerClose} />}
                {currentScreen === 3 && <Screen3 SaveChanges={SaveChanges} handleClickOpenDialog1={handleClickOpenDialog1} handleClickOpenDialog={handleClickOpenDialog} handleClickOpen={handleClickOpen} toggleDrawer2={toggleDrawer2} DrawerClose={DrawerClose} />}
                {currentScreen === 4 && <Screen4 DrawerClose={DrawerClose} onBack={handleBack} />}
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                PaperProps={{
                    style: {
                        width: '50%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        padding: '0px 25px',
                    },
                }}
            >
                <Box style={{ margin: 'auto', overflowX: 'hidden' }}>
                    <Typography
                        style={{
                            fontSize: '24px',
                            fontWeight: 500,
                            margin: '20px 0px',
                            textAlign: 'center',
                        }}
                    >
                        {iconName}
                    </Typography>
                    <Grid container spacing={4} style={{ margin: 'auto' }}>
                        {imageArrayData.map((item: any, index: number) => {
                            const isActive = (() => {
                                if (iconName === "Allergens") return activeIcons3.includes(item.id);
                                if (iconName === "Potty Training") return activeIcons1.includes(item.id);
                                if (iconName === "Naps") return activeIcons2.includes(item.id);
                                if (iconName === "Solid and Purees") return activeIcons5.includes(item.id);
                                return false;
                            })();

                            return (
                                <Grid key={index} item lg={4} md={4} sm={4} xs={4}>
                                    <Box
                                        onClick={() => toggleIconModal(item)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <img
                                            style={{ width: '60px', height: '60px' }}
                                            src={
                                                isActive
                                                    ? item.attributes.active_icon
                                                    : item.attributes.inactive_icon
                                            }
                                            alt="Icon"
                                        />
                                        <Typography>{item.attributes.name}</Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                        {iconName === "Naps" && (
                            <>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ margin: '0px 0px 10px' }}>Typical Start Time</Typography>
                                        <CustomTextField
                                            disabled={isDisabled}
                                            value={startTime}
                                            onChange={(e) => setStartTime(e.target.value)}
                                            style={Styles.textField1}
                                            id="outlined-basic"
                                            placeholder="Enter Time"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ margin: '0px 0px 10px' }}>Typical Duration</Typography>
                                        <CustomTextField
                                            disabled={isDisabled}
                                            value={duration}
                                            onChange={(e) => setDuration(e.target.value)}
                                            style={Styles.textField1}
                                            id="outlined-basic"
                                            placeholder="Enter duration"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '30px',
                            marginBottom: '20px',
                        }}
                    >
                        <Button onClick={handleCloseDialog}
                            style={{
                                width: '180px',
                                height: '50px',
                                backgroundColor: '#F1544B',
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 600,
                                borderRadius: '12px',
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openDialog1}
                onClose={handleCloseDialog1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                PaperProps={{
                    style: {
                        width: '50%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        padding: '0px 25px',
                    },
                }}
            >
                <Box style={{ margin: 'auto' }}>
                    <img width="100%" height="100%" src={cardImage} />
                </Box>
            </Dialog>

        </Box>
    )
}

export default AddChild;

const Styles = {
    textField1: {
        width: "100%",
        margin: "auto"
    },
    textField2: {
        width: "100%",
        border: '1px solid #3DB3E4',
        borderRadius: '10px'
    },
    plusIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    previewImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover' as 'cover',
    },
}