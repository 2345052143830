import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { background, logo, WelcomeImg } from "./assets";
// Customizable Area End

import HomePageController, {
    Props,
} from "./HomePageController.web";

export default class HomePage extends HomePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box style={Styles.mainBox}>
                <Box style={Styles.LoginLogo}>
                    <Box style={Styles.logoBox}>
                        <img style={Styles.logo} src={logo} />
                    </Box>
                    <img src={WelcomeImg} style={Styles.welcomeLogo} />
                    <Box style={Styles.buttonBox}>
                        <Button onClick={() => this.props.navigation.navigate('EmailAccountRegistration')} style={Styles.button1}>Parent</Button>
                        <Button onClick={() => this.goToLoginScreen()} style={Styles.button2}>Facility Manager</Button>
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        );
    }

}

// Customizable Area Start
const Styles = {
    mainBox: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px'

    },
    LoginLogo: {
        width: '390px',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0px 55px 33px 55px',
    },
    logoBox: {
        padding: '0px 30px'
    },
    logo: {
        width: '100%'
    },
    welcomeLogo: {
        margin: '30px auto',
        display: 'flex'
    },
    buttonBox: {
        display: 'grid',
        gap: '25px'
    },
    button1: {
        width: '100%',
        height: '60px',
        backgroundColor: 'F1544B',
        color:'#fff',
        fontSize:'16px',
        fontWeight: 600
    },
    button2: {
        width: '100%',
        height: '60px',
        backgroundColor: 'F1544B',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600
    }

}
// Customizable Area End
