import { Card, CardContent, Typography, Box, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider, TimePicker, TimePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, ReactElement } from "react";
import dayjs, { Dayjs } from "dayjs";

const StyledCard = styled(Card)({
  maxWidth: "250px",
  margin: "8px",
  backgroundColor: "#fff",
});

const TimeRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
});

const DayLabel = styled(Typography)({
  minWidth: "40px",
  fontSize: "0.875rem",
  fontWeight: "medium",
});

const AddMore = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: "medium",
  alignSelf: "center",
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
    color: "black",
  },
});

export type DayOfWeek = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

const DAYS: DayOfWeek[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export interface TimeSlot {
  open: Dayjs | null;
  close: Dayjs | null;
}

export type BusinessHoursData = Record<DayOfWeek, TimeSlot[]>;

const DEFAULT_HOURS: BusinessHoursData = {
  Mon: [{ open: dayjs("17:00", "HH:mm a"), close: dayjs("02:00", "HH:mm a") }],
  Tue: [{ open: dayjs("05:00", "HH:mm a"), close: dayjs("01:00", "HH:mm a") }],
  Wed: [{ open: dayjs("13:00", "HH:mm a"), close: dayjs("10:00", "HH:mm a") }],
  Thu: [{ open: dayjs("09:00", "HH:mm a"), close: dayjs("04:00", "HH:mm a") }],
  Fri: [{ open: dayjs("04:00", "HH:mm a"), close: dayjs("03:00", "HH:mm a") }],
  Sat: [{ open: dayjs("17:00", "HH:mm a"), close: dayjs("02:00", "HH:mm a") }],
  Sun: [{ open: dayjs("17:00", "HH:mm a"), close: dayjs("02:00", "HH:mm a") }],
};

interface BusinessHoursProps {
  initialHours?: BusinessHoursData;
  onChange?: (hours: BusinessHoursData) => void;
}

export default function TimeSlotCard({
  initialHours = DEFAULT_HOURS,
  onChange,
}: BusinessHoursProps): ReactElement {
  const [hours, setHours] = useState<BusinessHoursData>(initialHours);

  const handleTimeChange = (
    day: DayOfWeek,
    index: number,
    type: keyof TimeSlot,
    value: Dayjs | null
  ) => {
    const updatedHours = { ...hours };
    updatedHours[day][index][type] = value;
    setHours(updatedHours);
    onChange?.(updatedHours);
  };

  const removeTimeSlot = (day: DayOfWeek, index: number) => {
    const updatedHours = { ...hours };
    updatedHours[day] = updatedHours[day].filter((_, slotIndex) => slotIndex !== index);
    setHours(updatedHours);
    onChange?.(updatedHours);
  };
  
  const addTimeSlot = (day: DayOfWeek) => {
    const updatedHours = { ...hours };
    updatedHours[day] = [...updatedHours[day], { open: null, close: null }];
    setHours(updatedHours);
  };

  const handleSubmit = () => {
    console.log(
      Object.entries(hours).reduce((acc, [day, slots]) => {
        acc[day as DayOfWeek] = slots.map((slot) => ({
          open: slot.open?.format("HH:mm a") || "",
          close: slot.close?.format("HH:mm a") || "",
        }));
        return acc;
      }, {} as Record<DayOfWeek, { open: string; close: string }[]>)
    );
  };

  return (
    <StyledCard elevation={0} sx={{ maxWidth: "100%", overflowX: "clip" }}>
      <CardContent sx={{ p: 0 }}>
        <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem", fontWeight: "bold" }}>
          Hours
        </Typography>
        {DAYS.map((day) => (
          <Box key={day} sx={{ alignItems: "center", justifyContent: "center" }}>
            <DayLabel>{day}</DayLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {hours[day].map((slot, index) => (
                <TimeRow key={index} sx={{position:'relative'}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                      value={slot.open}
                      onChange={(value) => handleTimeChange(day, index, 'open', value)}
                      slots={{
                        textField: (props) => (
                          <TextField
                            {...props}
                            size="small"
                            style={{ width: '100px', padding: '0', fontSize: '0.875rem' }}
                          />
                        ),
                      }}
                    />
                    <TimePicker
                      value={slot.close}
                      onChange={(value) => handleTimeChange(day, index, 'close', value)}
                      slots={{
                        textField: (props) => (
                          <TextField
                            {...props}
                            size="small"
                            style={{ width: '100px', padding: '0', fontSize: '0.875rem' }}
                          />
                        ),
                      }}
                    />
                   
                  </LocalizationProvider>
                  <Button
                    onClick={() => removeTimeSlot(day, index)}
                    sx={{  color: 'red' , width:'10px', height:'10px' ,fontWeight:'700', flexShrink:1, position:'absolute' , left:'185',top:0}}
                    size='small'
                    type="button"
                  >X</Button>
                </TimeRow>
              ))}
            </Box>
            <AddMore onClick={() => addTimeSlot(day)}>Add More</AddMore>
          </Box>
        ))}
        <Box sx={{ textAlign: "center", marginTop: "16px" }}>
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "red",
              color: "white",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
