import React from 'react';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default function Step5Verify({ formikProps }: { formikProps: any }) {
  const { values, isSubmitting } = formikProps;

  const maskedAccountNumber = values.accountNumber 
    ? `****${values.accountNumber.slice(-4)}`
    : '****0000';

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
        ACH has been verified
      </Typography>

      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          my: 4,
          p: 3,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1
        }}
      >
        <AccountBalanceIcon sx={{ fontSize: 40, color: 'success.main' }} />
        <Box>
          <Typography variant="subtitle2" color="text.secondary">
            Account Number
          </Typography>
          <Typography variant="body1">
            {maskedAccountNumber}
          </Typography>
        </Box>
      </Box>

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        loading={isSubmitting}
        sx={{
          bgcolor: '#ff4d4f',
          '&:hover': {
            bgcolor: '#ff7875',
          },
        }}
      >
        Confirm
      </LoadingButton>
    </Box>
  );
}

