export const Alert = require("../assets/Alert.png");
export const ProfileImg = require("../assets/Profile.png");
export const img1 = require("../assets/None.png");
export const img2 = require("../assets/Egg.png");
export const img3 = require("../assets/Fish.png");
export const img4 = require("../assets/Peanut.png");
export const img5 = require("../assets/Shell Fish.png");
export const img6 = require("../assets/Soy.png");
export const img7 = require("../assets/Tree Nuts.png");
export const img8 = require("../assets/Gluten.png");
export const img9 = require("../assets/MIlk.png");
export const img10 = require("../assets/Diapers.png");
export const img11 = require("../assets/Group51.png");
export const img12 = require("../assets/Training Underwear.png");
export const img13 = require("../assets/Potty Trained.png");
export const img14 = require("../assets/Naps.png");
export const img15 = require("../assets/Solids.png");
export const img16 = require("../assets/Puree.png");
export const logo = require("../assets/recesslogo.png")


export const imageArray = [
    { image: img1, name: "None" },
    { image: img2, name: "Egg" },
    { image: img3, name: "Fish" },
    { image: img4, name: "Peanut" },
    { image: img5, name: "Shell Fish" },
    { image: img6, name: "Soy" },
    { image: img7, name: "Tree Nuts" },
    { image: img8, name: "Gluten" },
    { image: img9, name: "Milk" },
  ];

  export const imageArray1 = [
    { image: img10, name: "Diapers" },
    { image: img11, name: "Training in Diapers" },
    { image: img12, name: "Training in Underwear" },
    { image: img13, name: "Potty Trained" },
  ];

  export const imageArray2 = [
    { image: img1, name: "None" },
    { image: img14, name: "Naps" },
  ];

  export const imageArray3 = [
    { image: img15, name: "None" },
    { image: img16, name: "Naps" },
  ];