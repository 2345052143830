
import React from "react";
// Customizable Area Start
import { Box, Typography, CircularProgress ,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton } from "@mui/material";
import ProfileCard from "../../../components/src/ProfileCard";
import { ArrowForwardIos, Email } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

import { SideBar } from "../../../components/src/SideBar";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DashbordCaretakerScreen extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getLocatations()
    this.sendGetAPIRequest(webConfigJSON.getCareTakers,'apiCareTakerCallId')
  }
  componentDidUpdate(prevProps: Props, prevState: any) {
    // Check if the state value you're interested in has changed
    if (prevState.selectedLocation !== this.state.selectedLocation) {
      this.sendGetAPIRequest(webConfigJSON.getCareTakers,"apiCareTakerCallId");
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box width={'100vw'} height={'100vh'} >
        <SideBar selectedPage="CARETAKERS" navigateFun={this.navigateFun} SideBarContent={<></>}
         
          locations={this.state.locations ||[]}
          HeaderTitel={<Typography variant="h6" fontWeight="semibold" fontFamily={"Inter"} fontSize={'28px'}
          >
          {this.formatDate(Date.now())}
        </Typography>} 
        NavigationFunction={this.props.navigation.navigation}
        updateLocationState={this.updateLocationState}
        >
         <div style={{ padding: "20px" }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Caretaker - Adults
        </Typography>
                  
        
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{marginBottom:'30px'}}>
              <TableRow style={{ backgroundColor: "#f8d7da"  }}>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Phone Number
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Member Since
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
  
            <TableBody>
              {[ ...this.state.CaretakerData?.care_takers?.data ?? [],
  ...this.state.CaretakerData?.parents?.data ?? []].map((caretaker, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#fde2e4" : "#ffffff",
                  }}
                >
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={caretaker.attributes.profile_image_url}
                        alt={caretaker.attributes.first_name}
                        style={{ marginRight: "10px" }}
                      />
                      {caretaker.attributes.first_name} {caretaker.attributes.last_name}
                    </div>
                  </TableCell>
                  <TableCell>{caretaker.attributes.email}</TableCell>
                  <TableCell>{caretaker.attributes.phone_number}</TableCell>
                  <TableCell>{this.formatToDDMMMYY(caretaker.attributes.created_at)}</TableCell>
                  <TableCell>
                    <IconButton>
                      <ArrowForwardIos fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      
      </div>
        
       </SideBar>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


// Customizable Area End
