import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    styled,
    TextField,
    Typography,
} from "@material-ui/core";
import { backgroundImage, logo } from "../../email-account-login/src/assets";

// Customizable Area End

import WelcomeScreenController, {
    Props,
} from "./WelcomeScreenController.web";

export default class ParentVarify extends WelcomeScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box style={Styles.ParentmainBox1}>
                <Box style={Styles.ParentLoginLogo1}>
                    <Box style={Styles.ParentlogoBox1}>
                        <img style={Styles.Parentlogo1} src={logo} />
                    </Box>
                    <Typography style={Styles.ParentwelcomeLogo1}>Email Submitted!</Typography>
                    <Typography style={Styles.ParentwelcomeLogo2}>One of our representatives will get back to you shortly.</Typography>
                    <Box style={Styles.ParentbuttonBox1}>
                    <Button data-test-id = "parentLogin" onClick={this.goToLoginScreen} style={Styles.Parentbutton3}>Back to Login</Button>
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        );
    }

}

// Customizable Area Start
const Styles = {
    ParentmainBox1: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px'

    },
    ParentLoginLogo1: {
        width: '390px',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0px 55px 33px 55px',
    },
    ParentlogoBox1: {
        padding: '0px 30px'
    },
    Parentlogo1: {
        width: '100%'
    },
    ParentwelcomeLogo1: {
        margin: '30px auto 10px auto',
        textAlign:"center" as "center",
        fontSize: '30px',
        fontWeight: 600        
    },
    ParentwelcomeLogo2: {
        margin: '0px auto',
        textAlign:"center" as "center",
        fontSize: '20px',
        fontWeight: 500        
    },
    ParentbuttonBox1: {
        display: 'grid',
        gap: '25px'
    },
    Parentbutton: {
        width: '100%',
        height: '60px',
        backgroundColor: 'F1544B',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600
    },
    Parentbutton3: {
        width: '100%',
        height: '60px',
        color: '#F1544B',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'capitalize' as 'capitalize',
    },
    ParenttextField1: {
        width: "85%",
        margin: "auto"
    }

}
// Customizable Area End
