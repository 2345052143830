import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function Step3Photo({ formikProps }: { formikProps: any }) {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const [preview, setPreview] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      setFieldValue('photo', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
        Add Your Photo
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Add a clear photo of your face to be used for your profile and verification during pick-up and drop-off
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {preview ? (
          <Box
            sx={{
              width: 200,
              height: 200,
              borderRadius: '50%',
              overflow: 'hidden',
              mb: 2,
            }}
          >
            <img
              src={preview}
              alt="Preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        ) : (
          <Button
            component="label"
            variant="outlined"
            startIcon={<FileUploadIcon />}
            sx={{ mb: 2 }}
          >
            Upload image from computer
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>
        )}

        {preview && (
          <>
            <Button
              variant="outlined"
              component="label"
              sx={{ mb: 2 }}
            >
              Upload New Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>

            <LoadingButton
              fullWidth
              variant="contained"
              type="submit"
              loading={isSubmitting}
              sx={{
                bgcolor: '#ff4d4f',
                '&:hover': {
                  bgcolor: '#ff7875',
                },
              }}
            >
              Confirm
            </LoadingButton>
          </>
        )}
      </Box>
    </Box>
  );
}

