import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface Reservation {
  id: string;
  type: string;
  attributes: {
    reservation_date: string;
    drop_off_time: string;  
    childrens: Array<{
      full_name: string;
      image: string;         
      age: number;
      color: string;         
    }>;
    care_taker: CareTaker | null;
  };
}

interface CareTaker {
  full_name: string;
  image: string | null; 
}

interface Location {
  id: number;
  latitude: number | null;
  longitude: number | null;
  van_id: number | null;
  address: string;
  title: string;
  duration_id: number;
}

interface ReservationsResponse {
  upcoming: {
    data: Reservation[];
  };
  ongoing_reservations: {
    data: Reservation[];
  };
  completed: {
    data: Reservation[];
  };
};

interface PageAction {
  signup: boolean;
  setup_profile: boolean;
  child_profile: boolean;
}

interface CareTakerAttributes {
  first_name: string;
  last_name: string;
  phone_number: number | null;
  gender: string | null;
  email: string;
  member_id: string;
  designation: string;
  date_of_birth: string | null;
  address1: string | null;
  address2: string | null;
  state: string | null;
  city: string | null;
  zipcode: string | null;
  account_id: number;
  created_at: string;
  image: string | null;
  page_action: PageAction;
  is_parent: boolean;
  profile_image_url: string;
}

interface CareTaker {
  id: string;
  type: string;
  attributes: CareTakerAttributes;
}

interface ParentAttributes {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string | null;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  stripe_id: string;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  gender: string | null;
  age: number | null;
  designation: string;
  address1: string;
  address2: string;
  city: string;
  zipcode: string;
  state: string;
  member_id: string;
  page_action: PageAction;
  cancel_reason: string | null;
  parent_id: number | null;
  date_of_birth: string;
  profile_image_url: string;
}

interface Parent {
  id: string;
  type: string;
  attributes: ParentAttributes;
}

interface CareTakers {
  data: CareTaker[];
}

interface Parents {
  data: Parent[];
}

interface CareTakerApiResponse {
  care_takers: CareTakers;
  parents: Parents;
} 
type CallIdIterfaceforSendFunction = 
  | "apiDashboardItemCallId"
  | "dashboardApiCallId"
  | "apiGetQueryStrinurl"
  | "apiLocationsCallId"
  | "apiCareTakerCallId"
  | "apiChildreanCallId";

  export interface KidProfile {
    data: Kid[];
  }
  
  // Interface for a single Kid
  export interface Kid {
    id: string;
    type: string;
    attributes: KidAttributes;
  }
  
  // Interface for Kid Attributes
  export interface KidAttributes {
    first_name: string;
    last_name: string;
    gender: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string | null;
    zip_code: string;
    legal_custody: string | null;
    language: string;
    nap_time: string | null;
    is_complete: boolean;
    created_at: string;
    child_profile_image: string;
    age: number;
    date_of_birth: string;
    vaccination_upload_image: string | null;
    allergens: KidAllergen[];
    potty_trainings: KidPottyTraining[];
    solid_and_purees: KidSolidAndPuree[];
    special_note: KidSpecialNote;
    care_taker: KidCareTaker[];
  }
  
  // Interface for Allergens
  export interface KidAllergen {
    allergens_id: number;
    allergens_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Potty Training
  export interface KidPottyTraining {
    potty_trainings_id: number;
    potty_trainings_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Solid and Purees
  export interface KidSolidAndPuree {
    solid_and_purees_id: number;
    solid_and_purees_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Special Note
  export interface KidSpecialNote {
    special_note_id: number;
    special_note_description: string;
  }
  
  // Interface for Caretaker
  export interface KidCareTaker {
    id: number;
    full_name: string;
  }






// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  reservationData : ReservationsResponse|null;
  locations : Location[] |null;
  selectedLocation : number|undefined|null|string;
  CaretakerData : CareTakerApiResponse |null ;
  ChildreanData : KidProfile | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiLocationsCallId:string ="";
  apiCareTakerCallId:string ="";
  apiChildreanCallId : string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      reservationData:null,
      locations: null, 
      selectedLocation: localStorage.getItem('selectedLocation'),
      CaretakerData:null,
      ChildreanData:null,
    };
    this.updateLocationState = this.updateLocationState.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start 
    this.getLocatations();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.dashboardGetUrl}?location_id=${this.state.selectedLocation}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webApiRequestCallId != null) {
        switch (webApiRequestCallId) {
          case this.apiDashboardItemCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({reservationData:webResponseJson});
            }
            else{
              this.props.navigation.navigate("EmailAccountLoginBlock")
            }
            break;
          case this.apiLocationsCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({locations:webResponseJson.locations});
              localStorage.setItem('locations',JSON.stringify(webResponseJson.locations))
            }
            else{
              this.props.navigation.navigate("EmailAccountLoginBlock")
            }
            break;
          case this.apiCareTakerCallId:
              if (webResponseJson !== undefined && !webResponseJson.errors) {
                this.setState({CaretakerData:webResponseJson});
              }
              else{
                this.props.navigation.navigate("EmailAccountLoginBlock")
              }
              break;
          case this.apiChildreanCallId:
              if (webResponseJson !== undefined && !webResponseJson.errors) {
                  this.setState({ChildreanData:webResponseJson});
                }
              else{
                  this.props.navigation.navigate("EmailAccountLoginBlock")
                }
              break;
              
        }
      }
        
    }

    // Customizable Area End
  }
  // Customizable Area Start

  navigateFun = (data:any) => {
    if(data === "Active Bookings"){
      this.props.navigation.navigate("Dashboard")
    } else if(data === "Caretakers - Adults") {
      this.props.navigation.navigate("DashbordCaretakerScreen")
    } else if(data === "Members - Children"){
      this.props.navigation.navigate("DashboardChildrenScreen")
    } else if(data === "Incidents"){
      this.props.navigation.navigate("DashbordIncident")
    }
  }

  formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${minutes} ${suffix}`;
    const day = date.getDate();
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffixIndex = (day % 10 <= 3 && day % 100 > 20) ? day % 10 : 0;
    const dayWithSuffix = `${day}${suffixes[suffixIndex]}`;
    return `${formattedDate.replace(day.toString(), dayWithSuffix)} ${formattedTime}`;
  };

  getLocatations(): boolean {
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLocationsCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.locationListGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  updateLocationState(id:any):void{
    this.setState({selectedLocation:id}, ()=>{
      this.getDashboardData();
    })
  }


  sendGetAPIRequest(urlEndpoint:string, callId:CallIdIterfaceforSendFunction):boolean {
    const token = localStorage.getItem('authToken');
  
    if (token) {
      this.setState({ token: token });
    } else {
      this.props.navigation.navigate('EmailAccountLoginBlock');
      return false;
    }
  
    const webHeader = { token: token };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    // Assign the API call ID for tracking responses
    // this[callId] = webRequestMessage.messageId;
    
    // (this[`${callId as CallIdKeys}`]) = webRequestMessage.messageId;
    (this[callId as CallIdIterfaceforSendFunction]) = webRequestMessage.messageId;

  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlEndpoint}?location_id=${this.state.selectedLocation}`
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType // Assuming the method type is consistent across calls
    );
  
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }


  formatToDDMMMYY(dateString:string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const date = new Date(dateString);
    
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    
    return `${day}/${month}/${year}`;
  }
  // Customizable Area End
}
