import { Box, Button, Container, Grid, Typography, styled, TextField, FormControl, InputLabel, Select, MenuItem, Divider, Avatar, Drawer, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, NativeSelect } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
let profileImage = require('./image_profile1.png')
let Image = require('./Check.png')
const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "1px solid black",
        width: '100%'
    },
    selectEmpty: {
        marginTop: '0px',
    },
}));

const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
    },
});


const Screen1 = ({ preview, postEnrollChildData, state1, handleChangeSelect1, classes, handleChangeSelect, state, UpDateChildProfile, open2, onNext, DrawerClose, handleProfileImageChange, image, handleChange, formData }: any) => (
    <Container>
        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '50px 20px 15px' }}>
            <Typography style={{ fontSize: '32px', fontWeight: 500 }}>Invite Caretaker</Typography>
            <CloseIcon onClick={DrawerClose} style={{ color: '000000', cursor: 'pointer' }} />
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            {image ? (
                <Avatar
                    src={image}
                    style={{
                        width: '130px',
                        height: '130px',
                        marginRight: '20px',
                    }}
                />
            ) : (
                <AccountCircleIcon
                    style={{
                        color: '#E5E5E5',
                        width: '130px',
                        height: '130px',
                        paddingRight: '20px',
                    }}
                />
            )}

            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-profile-picture"
                onChange={handleProfileImageChange}
            />

            <label htmlFor="upload-profile-picture">
                <Button
                    component="span"
                    style={{
                        border: '1px solid #00000',
                        borderRadius: '10px',
                    }}
                >
                    Edit profile picture
                </Button>
            </label>
        </Box>
        <Box style={{ margin: '20px 0px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: 500, margin: '20px 0px' }}>Caretaker Information</Typography>
            <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} variant="outlined" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField
                        style={Styles.textField1}
                        id="outlined-basic"
                        placeholder="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <NativeSelect
                            id="demo-customized-select-native"
                            name="parant"
                            value={formData.parant}
                            onChange={handleChange}
                            input={<BootstrapInput />}
                        >
                            <option value="">Designation</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Step-Mother">Step-Mother</option>
                            <option value="Step-Father">Step-Father</option>
                            <option value="Grandmother">Grandmother</option>
                            <option value="Grandfather">Grandfather</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <NativeSelect
                            name="caretaker"
                            value={formData.caretaker}
                            onChange={handleChange}
                            id="demo-customized-select-native"
                            input={<BootstrapInput />}
                        >
                            <option value="">Role</option>
                            <option value="Primary Caretaker">Primary Caretaker</option>
                            <option value="Secondary Caretaker">Secondary Caretaker</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" placeholder="Email Address" name="email" value={formData.email} onChange={handleChange} variant="outlined" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomTextField style={Styles.textField1} id="outlined-basic" placeholder="Phone number" name="phoneno" value={formData.phoneno} onChange={handleChange} variant="outlined" />
                </Grid>
            </Grid>
        </Box>
        <Button onClick={() => postEnrollChildData(formData, preview)} style={{ width: '180px', height: '50px', backgroundColor: 'F1544B', color: '#fff', fontSize: '16px', fontWeight: 600, borderRadius: '12px' }}>Send Invite</Button>
    </Container>
);


function AddParent({ handleCloseDialog, InviteSuccess, getAllSolidandPureesData, getAllNapsData, getPottyTrainingData, get_allergenProfileData, postNotesgData, UpDateChildProfile, handleFormChange2, toggleDrawer2, open2, postPottyTrainingData, imageSelect, handleBoxClick, postEnrollChildData, handleChange, formData, imageArray, imageArray1, imageArray2, imageArray3, DrawerClose }: any) {
    const [currentScreen, setCurrentScreen] = useState(1);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [profileimage, setProfileImage] = useState<string | null>(null);
    const [open3, setOpen3] = React.useState(false);
    const [activeIcons, setActiveIcons] = useState<number[]>([]);
    const [activeIcons1, setActiveIcons1] = useState<number[]>([]);
    const [activeIcons2, setActiveIcons2] = useState<number[]>([]);
    const [notes, setNotes] = useState("");
    const [imageArrayData, setImageArrayData] = useState([]);
    const classes = useStyles();
    const [state, setState] = React.useState({
        age: '',
    });
    const [state1, setState1] = React.useState({
        age: '',
    });

    const handleChangeSelect = (event: any) => {
        setState({
            ...state,
            age: event.target.value,
        });
    };


    const handleChangeSelect1 = (event: any) => {
        setState1({
            ...state1,
            age: event.target.value,
        });
    };


    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
    };

    const toggleIcon = (item: any) => {
        setActiveIcons((prevActiveIcons) => {
            const isActive = prevActiveIcons.includes(item.id);
            if (!isActive) {
                handleBoxClick(item, item.id);
                return [...prevActiveIcons, item.id];
            } else {
                return prevActiveIcons.filter((iconId) => iconId !== item.id);
            }
        });
    };

    const toggleIcon1 = (item: any) => {
        setActiveIcons1((prevActiveIcons) => {
            const isActive = prevActiveIcons.includes(item.id);
            if (!isActive) {
                postPottyTrainingData(item, item.id);
                return [...prevActiveIcons, item.id];
            } else {
                return prevActiveIcons.filter((iconId) => iconId !== item.id);
            }
        });
    };

    const toggleIcon2 = (item: any) => {
        setActiveIcons2((prevActiveIcons) => {
            const isActive = prevActiveIcons.includes(item.id);
            if (!isActive) {
                postPottyTrainingData(item, item.id);
                return [...prevActiveIcons, item.id];
            } else {
                return prevActiveIcons.filter((iconId) => iconId !== item.id);
            }
        });
    };


    const handleClickOpen = (toggleDrawer2: any) => {
        setOpen3(true);
        setCurrentScreen(1)
        handleFormChange2(toggleDrawer2)
    };

    const handleClose = () => {
        setOpen3(false);
    };

    const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files?.[0];
        setPreview(file)
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };



    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        setImage(event.target.files[0]);
    };


    const handleNext = () => {
        DrawerClose()
        console.log(currentScreen, 'currentScreen');
    };

    const handleBack = () => {
        setCurrentScreen((prev) => Math.max(prev - 1, 1));
    };

    return (
        <Box style={{ padding: '0px 25px 25px 25px' }}>
            <Box>
                {currentScreen === 1 && <Screen1 preview={preview} postEnrollChildData={postEnrollChildData} classes={classes} handleChangeSelect1={handleChangeSelect1} state1={state1} handleChangeSelect={handleChangeSelect} state={state} UpDateChildProfile={UpDateChildProfile} open2={open3} handleChange={handleChange} formData={formData} onNext={handleNext} handleProfileImageChange={handleProfileImageChange} image={profileimage} DrawerClose={DrawerClose} />}
                {/* {currentScreen === 2 && DrawerClose()} */}
                {/* {currentScreen === 2 && <Screen2 notes={notes} handleNotesChange={handleNotesChange} toggleIcon2={toggleIcon2} activeIcons2={activeIcons2} toggleIcon1={toggleIcon1} activeIcons1={activeIcons1} toggleIcon={toggleIcon} activeIcons={activeIcons} postPottyTrainingData={postPottyTrainingData} imageSelect={imageSelect} handleBoxClick={handleBoxClick} imageArray={imageArray} imageArray2={imageArray2} imageArray1={imageArray1} imageArray3={imageArray3} onNext={handleNext} onBack={handleBack} preview={preview} handleImageChange={handleImageChange} DrawerClose={DrawerClose} />} */}
                {/* {currentScreen === 3 && <Screen3 handleClickOpenDialog={handleClickOpenDialog} handleClickOpen={handleClickOpen} toggleDrawer2={toggleDrawer2} DrawerClose={DrawerClose} />} */}
            </Box>
            <Dialog
                open={InviteSuccess}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                PaperProps={{
                    style: {
                        width: '30%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        padding: '0px 25px',
                        height: '225px',
                        textAlign: 'center',
                        borderRadius: '15px'
                    }
                }}
            >
                <Box style={{ margin: 'auto' }}>
                    <img width="100px" height="100px" src={Image} />
                    <Typography style={{ marginTop: '20px' }}>Invite has been sent!</Typography>
                    <Typography onClick={handleCloseDialog} style={{ marginTop: '20px', cursor: 'pointer' }}>Close</Typography>
                </Box>
            </Dialog>
        </Box>
    )
}

export default AddParent;

const Styles = {
    textField1: {
        width: "100%",
        margin: "auto"
    },
    textField2: {
        width: "100%",
        border: '1px solid #3DB3E4',
        borderRadius: '10px'
    },
    plusIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    previewImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover' as 'cover',
    },
}