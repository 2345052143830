import React, { useState } from "react";
import {
    SwipeableDrawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Box,
    Drawer as PermanentDrawer,
    AppBar,
    Toolbar,
    useMediaQuery,
    useTheme,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
let Logo = require('./recess-logo.png')
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/Payment';
import ChildCareIcon from '@mui/icons-material/Receipt';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
export enum NavigationItem {
    HOME = "Home",
    PAYMENT = "Payment",
    BILLING = "Billing",
}
export const navigationIcons = {
    [NavigationItem.HOME]: HomeIcon,
    [NavigationItem.PAYMENT]: PeopleIcon,
    [NavigationItem.BILLING]: ChildCareIcon,
};
const drawerWidth = 280;

function ParantSideBar() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState<NavigationItem>(NavigationItem.HOME);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        } else {
            setDesktopOpen(!desktopOpen);
        }
    };

    const handleDrawerClose = () => {
        if (isMobile) {
            setMobileOpen(false);
        } else {
            setDesktopOpen(false);
        }
    };

    const drawerContent = (
        <Box sx={{ width: drawerWidth - 40, p: 2 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ height: "90px" }}>
                <Typography variant="h4" fontWeight="bold" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} alt='logo' style={{ height: '29px', width: '150px' }} />
                </Typography>
                <IconButton onClick={handleDrawerClose} sx={{ border: '1px solid  #9499a1', borderRadius: '50%', width: '30px', height: '30px' }}>
                    {isMobile ? <CloseIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </Box>

            <List>
                {Object.values(NavigationItem).map((item) => {
                    const Icon = navigationIcons[item];
                    return (
                        <ListItem
                            key={item}
                            button
                            selected={selectedItem === item}
                            sx={{
                                backgroundColor: selectedItem === item ? "#f1544b" : "transparent",
                                color: selectedItem === item ? "white" : "#9499a1",
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: selectedItem === item ? "darkred" : "#f1544b",
                                },
                                "&.Mui-selected": {
                                    backgroundColor: "#f1544b",
                                    "&:hover": {
                                        backgroundColor: "#f1544b",
                                    },
                                },
                                mb: '20px',
                                w: '90%',
                                borderRadius: '15px'
                            }}

                            onClick={() => setSelectedItem(item)}
                        >
                            <ListItemIcon sx={{ color: selectedItem === item ? "#ffff" : "#9499a1" }}>
                                <Icon />
                            </ListItemIcon>
                            <ListItemText primary={item} sx={{ w: '150px' }} />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );

    return (
        <>
            <Box
                component="nav"
                sx={{ width: { sm: desktopOpen ? drawerWidth : 0 }, flexShrink: { sm: 0 } }}
            >
                <SwipeableDrawer
                    variant="temporary"
                    open={mobileOpen}
                    onOpen={() => setMobileOpen(true)}
                    onClose={() => setMobileOpen(false)}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawerContent}
                </SwipeableDrawer>
                <PermanentDrawer
                    variant="persistent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open={desktopOpen}
                >
                    {drawerContent}
                </PermanentDrawer>
            </Box>
            {!desktopOpen && !isMobile && (
                <>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            position: 'fixed', left: 16, top: 40, zIndex: theme.zIndex.drawer + 1
                            , border: '1px solid  #9499a1', borderRadius: '50%', width: '30px', height: '30px'
                        }}

                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Box sx={{ padding:'0px 15px'}}/>
                </>
            )}
        </>
    )
}

export default ParantSideBar;