import React from 'react';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ProfileComplete({navfn}:{navfn:()=>void}) {
  const handleEnrollChild = () => {
    navfn()
  };

  return (
    <Box sx={{ textAlign: 'center', py: 8 }}>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          fontWeight: 'bold',
          mb: 3,
          fontSize: { xs: '2rem', sm: '3rem' }
        }}
      >
        Profile Complete!
      </Typography>
      
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 6, maxWidth: '400px', mx: 'auto' }}
      >
        Thanks for completing your profile. Now, let's move on to enrolling your child or children so you can start using Recess!
      </Typography>

      <LoadingButton
        fullWidth
        variant="contained"
        onClick={handleEnrollChild}
        sx={{
          bgcolor: '#ff4d4f',
          '&:hover': {
            bgcolor: '#ff7875',
          },
          maxWidth: '400px'
        }}
      >
        Enroll Child
      </LoadingButton>
    </Box>
  );
}

