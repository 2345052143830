import React, { useState } from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SignatureCanvas from 'react-signature-canvas';

export default function Step6Terms({ formikProps ,Terms }: { formikProps: any , Terms :{
    title: string,
    description: string
}[]|null}) {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const [showSignature, setShowSignature] = useState(false);

  const termsdescription = Terms??[]

  if (!showSignature) {
    return (
      <Box>
        <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
          Terms and Conditions
        </Typography>

        <Box sx={{ height: '400px', overflowY: 'auto', mb: 3 }}>
          {termsdescription.map((term, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {term.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {term.description}
              </Typography>
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={values.acceptTerms}
              onChange={(e) => {
                setFieldValue('acceptTerms', e.target.checked);
                if (e.target.checked) {
                  setShowSignature(true);
                }
              }}
            />
          }
          label="I accept these terms"
        />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
        Signature
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        For added security and to meet legal requirements, we require your signature. This signature will be used for verification, compliance, or other documentation when and where necessary.
      </Typography>

      <Box 
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          mb: 3
        }}
      >
        <SignatureCanvas
          canvasProps={{
            width: 500,
            height: 200,
            className: 'signature-canvas'
          }}
          onEnd={() => setFieldValue('signature', true)}
        />
      </Box>

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        loading={isSubmitting}
        disabled={!values.signature}
        sx={{
          bgcolor: '#ff4d4f',
          '&:hover': {
            bgcolor: '#ff7875',
          },
        }}
      >
        Next
      </LoadingButton>
    </Box>
  );
}

