import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from 'moment'
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface FormValuesInterface {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  designation: string,
  birthday: string,

  addressLine1: string,
  addressLine2: string,
  aptNumber: string,
  city: string,
  state: string,
  zipcode: string,

  photo: any,
  photoPreview: string,

  accountHolderName: string,
  accountNumber: string,
  confirmAccountNumber: string,
  routingNumber: string,
  confirmRoutingNumber: string,
  billingZipCode: string,
  acceptTerms: boolean,
  bankAccID: any,
}

interface TermsContentItem {
  title: string;
  description: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  ChildDataID: string;
  cancelEnabled?: boolean;
  allergen: object;
  InviteSuccess: boolean;
  pottyTraining: object;
  getAllNap: object;
  getSolidandPurees: object;
  getParentsUserData: any;
  getChildData: any;
  getParantData: any;
  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  imageSelect: boolean;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  open: boolean;
  openParent: boolean;
  open2: number;
  toggleDrawer2: any;
  selected: null | string;
  profileImageEdit: any;
  handleChild: boolean;
  formDataParant: {
    id: string,
    firstName: string,
    lastName: string,
    parant: string,
    caretaker: string,
    email: any,
    phoneno: string,
  },
  formData: {
    id: string,
    firstName: string,
    lastName: string,
    age: string,
    addressLine1: string,
    addressLine2: string,
    apt: string,
    city: string,
    state: string,
    zipcode: string,
    legalCustody: string,
    language: string,
    birthday: any,
    gender: string,
  },

  formValues: FormValuesInterface;
  activeStep: number;
  Terms: TermsContentItem[] | null;
  bankid: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  ChildProfileApiCallId: string = "";
  getChildProfileDataCallId: string = "";
  getParantProfileDataCallId: string = "";
  getAllergenProfileDataCallId: string = "";
  getParentsUserDataCallId: string = "";
  getPottyTrainingDataDataCallId: string = "";
  getAllNapsDataCallId: string = "";
  getAllSolidandPureesDataCallId: string = "";
  postEnrollChildDataCallId: string = "";
  postallergenDataCallId: string = "";
  postPottyTrainingDataDataCallId: string = "";
  postChildSolidPureesDataCallId: string = "";
  postVaccinationUploadCallId: string = "";
  postParantInviteaDataCallId: string = "";
  postNapsTimingDataCallId: string = "";
  postNotesDataDataCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  createAccountApiCallId: string = "";
  banckAccCreateCallid: string = "";
  banckAccverifyCallid: string = "";
  getTandCCallId: string = ""
  postFromData: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      selected: null,
      profileImageEdit: "",
      fields: [],
      allergen: {},
      InviteSuccess: false,
      getChildData: {},
      getParantData: {},
      pottyTraining: {},
      getParentsUserData: {},
      getAllNap: {},
      getSolidandPurees: {},
      attributes: [],
      attributesValues: [],
      ChildDataID: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      imageSelect: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      open: false,
      openParent: false,
      open2: 0,
      toggleDrawer2: {},
      handleChild: true,
      formData: {
        id: '',
        firstName: '',
        lastName: '',
        age: '',
        addressLine1: '',
        addressLine2: '',
        apt: '',
        city: '',
        state: '',
        zipcode: '',
        legalCustody: '',
        language: '',
        birthday: '',
        gender: '',
      },
      formDataParant: {
        id: '',
        firstName: '',
        lastName: '',
        parant: '',
        caretaker: '',
        email: '',
        phoneno: ''
      },

      formValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        designation: '',
        birthday: '',

        addressLine1: '',
        addressLine2: '',
        aptNumber: '',
        city: '',
        state: '',
        zipcode: '',

        photo: null,
        photoPreview: '',

        accountHolderName: '',
        accountNumber: '',
        confirmAccountNumber: '',
        routingNumber: '',
        confirmRoutingNumber: '',
        billingZipCode: '',
        acceptTerms: false,
        bankAccID: {}
      },
      activeStep: 0,
      bankid: "",
      Terms: null,

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getChildProfileData();
    this.getParantProfileData();
    this.get_allergenProfileData();
    this.getPottyTrainingData();
    this.getAllNapsData();
    this.getAllSolidandPureesData();
    this.getParantDataNewUser();
    this.getTearms()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }

  };

  checkCreateAcc = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.createAccountApiCallId) {
      if (!responseJson?.errors) {
        this.setState({ activeStep: 3 })
      }
    }
    else if (apiRequestCallId === this.banckAccCreateCallid) {
      if (!responseJson?.error) {
        console.log(responseJson)
        this.setState({ activeStep: 4, formValues: { ...this.state.formValues, bankAccID: responseJson, aptNumber: responseJson.bank_account.data.id }, bankid: responseJson.bank_account.data.id })
        console.log(this.state.formValues, 'fomvals')
      }
    }
    else if (apiRequestCallId === this.banckAccverifyCallid) {
      if (!responseJson?.error) {
        this.setState({ activeStep: 5 })
      }
    }
    else if (apiRequestCallId === this.getTandCCallId) {
      if (!responseJson?.error) {
        this.setState({ Terms: responseJson })
        console.log(this.state.Terms, 'terms')
      }
    }
    else if (apiRequestCallId === this.postFromData) {
      if (!responseJson?.error) {
        this.setState({ activeStep: 6 })
      }
    }

  }

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    } else if (apiRequestCallId === this.getChildProfileDataCallId) {
      this.setState({ getChildData: responseJson })
    } else if (apiRequestCallId === this.getParantProfileDataCallId) {
      this.setState({ getParantData: responseJson })
    } else if (apiRequestCallId === this.getAllergenProfileDataCallId) {
      this.setState({ allergen: responseJson.data })
    } else if (apiRequestCallId === this.getPottyTrainingDataDataCallId) {
      this.setState({ pottyTraining: responseJson.data })
    } else if (apiRequestCallId === this.getAllNapsDataCallId) {
      this.setState({ getAllNap: responseJson.data })
    } else if (apiRequestCallId === this.getAllSolidandPureesDataCallId) {
      this.setState({ getSolidandPurees: responseJson.data })
    } else if (apiRequestCallId === this.postEnrollChildDataCallId) {
      if (responseJson) {
        alert("Profile Updated Successfully");
        this.setState({ ChildDataID: responseJson.data?.id })
      }
    } else if (apiRequestCallId === this.postallergenDataCallId) {
      this.getPottyTrainingData()
      this.get_allergenProfileData()
    } else if (apiRequestCallId === this.postPottyTrainingDataDataCallId) {
      this.getPottyTrainingData()
    } else if (apiRequestCallId === this.getParentsUserDataCallId) {
      this.setState({ getParentsUserData: responseJson?.data  , formValues:{...this.state.formValues, email: responseJson?.data[0].attributes?.email}})
    } else if (apiRequestCallId === this.ChildProfileApiCallId) {
      if (responseJson) {
        alert("Profile Updated Successfully");
        this.setState({ ChildDataID: responseJson.data?.id })
        this.getChildProfileData();
        this.setState({ open: false })
      } else {
        alert("Please Try again");
      }
    } else if (apiRequestCallId === this.postNotesDataDataCallId) {
      this.setState({ ChildDataID: responseJson.data?.id })
    } else if (apiRequestCallId === this.postNapsTimingDataCallId) {
      this.getAllNapsData()
    } else if (apiRequestCallId === this.postChildSolidPureesDataCallId) {
      this.getAllSolidandPureesData()
    } else if (apiRequestCallId === this.postVaccinationUploadCallId) {
      this.getChildProfileData()
    } else if (apiRequestCallId === this.postParantInviteaDataCallId) {
      if (responseJson.activated === true) {
        this.handleClickOpenDialog()
      } else {
        alert(`Error: ${responseJson.email}`)
      }
      this.getParantProfileData();
    }

  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson) {
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);

      }
      this.checkCreateAcc(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleFormChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    console.log(name, value, 'parent');

    this.setState((prevState: any) => ({
      formData: {
        ...prevState.formData,
        [name || '']: value,
      },
    }));
  };

  handleFormChangeParant = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }>) => {
    const { name, value } = e.target;

    this.setState((prevState: any) => ({
      formDataParant: {
        ...prevState.formDataParant,
        [name || '']: value,
      },
    }));
  };

  handleClickOpenDialog = () => {
    this.setState({ InviteSuccess: true })
  };

  handleCloseDialog = () => {
    this.setState({ InviteSuccess: false })

  };



  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state?.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    this.fetchAttributes();
  }

  calculateAge(dateOfBirth: any) {
    const birthDate = moment(dateOfBirth, "MMMM Do, YYYY");
    const today = moment();

    return today.diff(birthDate, "years");
  }

  toggleDrawerParent = (event: any) => {
    this.setState({ openParent: !this.state.openParent })
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent).key === "Tab" ||
      (event as React.KeyboardEvent).key === "Shift"
    ) {
      return;
    }
  }

  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent).key === "Tab" ||
      (event as React.KeyboardEvent).key === "Shift"
    ) {
      return;
    }
    this.setState((prevState) => {
      const isDrawerOpen = !prevState.open;

      return {
        open: isDrawerOpen,
        open2: 1,
        // handleChild: name === 'child',
        // handleChild: false,
        selected: null,
        //  profileImageEdit: '' ,
        formData: {
          id: '',
          firstName: '',
          lastName: '',
          age: '',
          addressLine1: '',
          addressLine2: '',
          apt: '',
          city: '',
          state: '',
          zipcode: '',
          legalCustody: '',
          language: '',
          birthday: '',
          gender: '',
        },
      }
    });
  };

  toggleDrawer2 = (data: any) => {
    this.setState({ open: !this.state.open }, () => {
      this.setState({ selected: null })
      this.setState({ open2: 3 })
      this.setState({ toggleDrawer2: data })
      this.setState({ ChildDataID: data.id })
      this.getChildProfileData();
      this.setState({
        formData: {
          id: '',
          firstName: '',
          lastName: '',
          age: '',
          addressLine1: '',
          addressLine2: '',
          apt: '',
          city: '',
          state: '',
          zipcode: '',
          legalCustody: '',
          language: '',
          birthday: '',
          gender: ''
        },
      })
    })

  };

  handleFormChange2 = (data: any) => {
    const dateString = data?.attributes?.date_of_birth || "";
    const formattedDate = moment(dateString, "MMMM Do, YYYY").format("MM/DD/YYYY");

    this.setState({
      formData: {
        id: data?.id,
        firstName: data?.attributes?.first_name,
        lastName: data?.attributes?.last_name,
        age: data?.attributes?.age,
        addressLine1: data?.attributes?.address1,
        addressLine2: data?.attributes?.address2,
        apt: data?.attributes?.address1,
        city: data?.attributes?.city,
        state: data?.attributes?.state,
        zipcode: data?.attributes?.zip_code,
        legalCustody: data?.attributes?.legal_custody,
        language: data?.attributes?.language,
        birthday: formattedDate,
        gender: data?.attributes?.gender
      },
    }, () => {
      this.setState({ selected: data?.attributes?.gender })
      this.setState({ profileImageEdit: data?.attributes?.child_profile_image })
    })
  };

  handleButtonClick = (gender: string) => {
    this.setState({ selected: gender })
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        gender: gender,
      },
    }));
  };

  DrawerClose = () => {
    this.setState({ open: false }, () => {
      this.setState({ open2: 0 })
      this.setState({ toggleDrawer2: {} })
      this.getChildProfileData();
    })
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  UpDateChildProfile = async (FData: any, image: any) => {
    let file: File | null = null
    const response = await fetch(this.state.profileImageEdit);
    const blob = await response.blob();
    file = new File([blob], "EditprofileImage.jpg", {
      type: blob.type,
      lastModified: new Date().getTime(),
    });
    
    const formattedDate = moment(FData.birthday, "MM/DD/YYYY").format("YYYY-MM-DD");
    const token = localStorage.getItem("authTokenParant")
    const header = {
      "token": token
    };

    let formData = new FormData();
    formData.append("first_name", FData.firstName);
    formData.append("last_name", FData.lastName);
    formData.append("data[child_profile_image]", image === null ? file : image);
    formData.append("date_of_birth", formattedDate);
    formData.append("gender", FData.gender);
    formData.append("address1", FData.addressLine1);
    formData.append("address2", FData.addressLine2);
    formData.append("city", FData.city);
    formData.append("state", FData.state);
    formData.append("country", "India");
    formData.append("zip_code", FData.zipcode);
    formData.append("legal_custody", FData.legalCustody);
    formData.append("language", FData.language);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ChildProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_profiles/${FData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getChildProfileData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getChildProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_profiles`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getParantProfileData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getParantProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/care_takers`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_allergenProfileData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllergenProfileDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/get_all_allergen`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPottyTrainingData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPottyTrainingDataDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/get_all_pottytraining`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllNapsData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllNapsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/get_all_naps`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSolidandPureesData = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllSolidandPureesDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/get_all_solidandpuree`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postEnrollChildData = async (finalData: any) => {
    const formattedDate = moment(finalData.birthday, "MM/DD/YYYY").format("YYYY-MM-DD");
    const token = await getStorageData('authTokenParant')
    const header = {
      token: token
    };

    const formData = new FormData();
    formData.append("data[first_name]", finalData.firstName);
    formData.append("data[last_name]", finalData.lastName);
    // formData.append("data[child_profile_image]", finalData.profileImage);
    formData.append("data[child_profile_image]", finalData.profileImage);
    formData.append("data[date_of_birth]", formattedDate);
    formData.append("data[gender]", finalData.gender);
    formData.append("data[address1]", finalData.addressLine1);
    formData.append("data[address2]", finalData.addressLine2);
    formData.append("data[city]", finalData.city);
    formData.append("data[state]", finalData.state);
    formData.append("data[country]", "India");
    formData.append("data[zip_code]", finalData.zipcode);
    formData.append("data[legal_custody]", finalData.legalCustody);
    formData.append("data[language]", finalData.language);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postEnrollChildDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_profiles`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postNotesgData = async (imageArray: any, index: any) => {
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("description", imageArray)


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postNotesDataDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/special_notes`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  parantInvite = async (imageArray: any, preview:any) => {
    const token = await getStorageData('authTokenParant')
    const header = {
      token: token
    };

    const formData = new FormData();
    formData.append("first_name", imageArray.firstName);
    formData.append("last_name", imageArray.lastName);
    formData.append("email", imageArray.email);
    formData.append("phone_number", imageArray.phoneno);
    formData.append("designation", imageArray.parant);
    formData.append("image",preview )

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postParantInviteaDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/care_takers`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  postAllergendData = async (imageArray: any) => {
    const numericArray: any = imageArray?.map((item: any) => parseInt(item, 10));
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("allergen_id", JSON.stringify(numericArray))


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postallergenDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_add_allergen`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postPottyTrainingData = async (imageArray: any) => {
    const numericArray: any = imageArray.map((item: any) => parseInt(item, 10));
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("potty_training_id", JSON.stringify(numericArray))


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postPottyTrainingDataDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_potty_training`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postChildSolidPureesData = async (imageArray: any) => {
    const numericArray = imageArray.map((item: any) => parseInt(item, 10));
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("solid_and_purees_id", JSON.stringify(numericArray))


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postChildSolidPureesDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/child_solid_and_purees`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postNapsTimingData = async (startTime: any, duration: any) => {
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("data[nap_start_time]", startTime);
    formData.append("data[nap_duration]", duration);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postNapsTimingDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/nap_time`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postVaccinationUploadData = async (imageArray: any) => {
    const token = await getStorageData('authTokenParant')
    const header = {
      ContentType: "application/json",
      token: token
    };

    const formData = new FormData();
    formData.append("id", this.state.ChildDataID);
    formData.append("data[vaccination_upload_image]", imageArray);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVaccinationUploadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_customisableuserprofiles/vaccination_upload_image`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateValues(values: FormValuesInterface) {
    this.setState({ formValues: values })
  }

  //validation 
  validateStep1(values: any) {
    const errors: any = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;

    if (!values.firstName) errors.firstName = 'First name is required';
    if (!values.lastName) errors.lastName = 'Last name is required';
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = 'Invalid phone number';
    }
    if (!values.designation) errors.designation = 'Designation is required';
    if (!values.birthday) errors.birthday = 'Birthday is required';

    return errors;
  };

  validateStep2(values: any) {
    const errors: any = {};
    const zipcodeRegex = /^\d{5}(-\d{4})?$/;

    if (!values.addressLine1) errors.addressLine1 = 'Address is required';
    if (!values.city) errors.city = 'City is required';
    if (!values.state) errors.state = 'State is required';
    if (!values.zipcode) {
      errors.zipcode = 'Zipcode is required';
    } else if (!zipcodeRegex.test(values.zipcode)) {
      errors.zipcode = 'Invalid zipcode format';
    }

    return errors;
  };

  validateStep3(values: any) {
    const errors: any = {};
    if (!values.photo) errors.photo = 'Photo is required';
    return errors;
  };


  validateStep4 = (values: any) => {
    const errors: any = {};
    const routingRegex = /^\d{9}$/;
    const accountRegex = /^\d{4,17}$/;
    const zipRegex = /^\d{5}(-\d{4})?$/;

    if (!values.accountHolderName) errors.accountHolderName = 'Account holder name is required';
    if (!values.accountNumber) {
      errors.accountNumber = 'Account number is required';
    } else if (!accountRegex.test(values.accountNumber)) {
      errors.accountNumber = 'Invalid account number';
    }
    if (!values.confirmAccountNumber) {
      errors.confirmAccountNumber = 'Please confirm account number';
    } else if (values.accountNumber !== values.confirmAccountNumber) {
      errors.confirmAccountNumber = 'Account numbers do not match';
    }
    if (!values.routingNumber) {
      errors.routingNumber = 'Routing number is required';
    } else if (!routingRegex.test(values.routingNumber)) {
      errors.routingNumber = 'Invalid routing number';
    }
    if (!values.confirmRoutingNumber) {
      errors.confirmRoutingNumber = 'Please confirm routing number';
    } else if (values.routingNumber !== values.confirmRoutingNumber) {
      errors.confirmRoutingNumber = 'Routing numbers do not match';
    }
    if (!values.billingZipCode) {
      errors.billingZipCode = 'Billing zip code is required';
    } else if (!zipRegex.test(values.billingZipCode)) {
      errors.billingZipCode = 'Invalid zip code';
    }

    return errors;
  };

  validateStep5 = (values: any) => {
    return {}; // No validation needed for verification step
  };

  validateStep6 = (values: any) => {
    const errors: any = {};
    if (!values.acceptTerms) errors.acceptTerms = 'You must accept the terms to continue';
    return errors;
  };


  validationFunctions = [this.validateStep1, this.validateStep2, this.validateStep3, this.validateStep4, this.validateStep5, this.validateStep6];


  handleBack() {

    if (this.state.activeStep <= 2) {
      this.setState({ activeStep: Math.max(0, this.state.activeStep - 1) })
    }
    
  };

  handleNext() {
    if (this.state.activeStep < 2) {

      this.setState({ activeStep: Math.min(5, this.state.activeStep + 1) });
    } else if (this.state.activeStep === 2) {
      this.createAccount()
    }
    if (this.state.activeStep === 3) {
      this.createBanckAccount()
    }
    if (this.state.activeStep === 4) {
      // console.log(this.state.formValues)

      this.verifyBanckAccount()
      this.getTearms()
    }
    if (this.state.activeStep === 5) {
      this.postTearms()

    }
  };



  createAccount() {
    const token = localStorage.getItem("authToken")
    const header = {
      "token": token
    };
    let userImage: any = {
      uri: this.state.formValues.photo,
      // type: this.state.editImageUrl,
      type: 'image/jpeg',
      name: 'Profileimage.jpg',
    }

    let formdata = new FormData();
    const plainPhoneNumber = (this.state.formValues.phone ?? '').replace(/-/g, '');

    formdata.append("account[first_name]", this.state.formValues.firstName);
    formdata.append("account[last_name]", this.state.formValues.lastName);
    formdata.append("account[email]", this.state.formValues.email?.toLowerCase());
    formdata.append("account[phone_number]", plainPhoneNumber)
    formdata.append("account[designation]", this.state.formValues.designation);
    formdata.append("account[date_of_birth]", moment(this.state.formValues.birthday)?.toString());
    formdata.append("account[address1]", this.state.formValues.addressLine1);
    formdata.append("account[city]", this.state.formValues.city);
    formdata.append("account[state]", this.state.formValues.state);
    formdata.append("account[address2]", this.state.formValues.firstName);
    formdata.append("account[zipcode]", this.state.formValues.zipcode);
    formdata.append("account[image]", this.state.formValues.photo)


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apipatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getParantDataNewUser = async () => {
    const token = await getStorageData('authTokenParant')
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getParentsUserDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_parents`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  createBanckAccount() {

    const token = localStorage.getItem("authToken")
    const header = {
      "token": token,
      "Content-Type": configJSON.searchApiContentType
    };

    let formdata = {
      "bank_account": {
        "account_holder_name": this.state.formValues.accountHolderName,
        "account_number": this.state.formValues.accountNumber,
        "routing_number": this.state.formValues.routingNumber,
        "billing_zip_code": this.state.formValues.billingZipCode
      }
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.banckAccCreateCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankCreateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  verifyBanckAccount() {

    const token = localStorage.getItem("authToken")
    const header = {
      "token": token,
    };

    let formdata = new FormData();
    console.log('frmdta', this.state.formValues.bankAccID)

    console.log('frmdta', this.state.formValues.bankAccID?.data)

    console.log('frmdta', this.state.bankid)

    console.log('frmdta', this.state.formValues.bankAccID?.aptNumber)

    formdata.append(
      "id",
      this.state.bankid || this.state.formValues.bankAccID?.bank_account?.data?.id || this.state.formValues?.aptNumber
    );
    formdata.append("amounts[]", '32');
    formdata.append("amounts[]", '45');


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.banckAccverifyCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankVerifyAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTearms() {

    const token = localStorage.getItem("authToken")
    const header = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //todo
    this.getTandCCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndC
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  postTearms() {

    const token = localStorage.getItem("authToken")
    const header = {
      "token": token,
      "Content-Type": configJSON.searchApiContentType
    };
    let formdta = {
      "title": "Acceptance of Terms",
      "description": "By downloading, accessing, or using the Recess Childcare app, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions."
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //todo
    this.postFromData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndC
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdta)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // goToLoginScreen
  goToChildProfile = () => {
    this.props.navigation.navigate('CustomisableUserProfiles')
  }

  setIntialFormEmailValue =(parentEmail:string)=>{
    this.setState({formValues:{...this.state.formValues ,email:parentEmail}})
  }
  // Customizable Area End
}
