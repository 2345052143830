import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function Step4ACH({ formikProps }: { formikProps: any }) {
  const { values, errors, touched, handleChange, isSubmitting } = formikProps;

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
        Add ACH Payment
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Please complete this form to set up electronic payments.This payment method will be used for all bookings made through the app as well as fees for enrollment.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          name="accountHolderName"
          label="Account Holder Name"
          value={values.accountHolderName}
          onChange={handleChange}
          error={touched.accountHolderName && Boolean(errors.accountHolderName)}
          helperText={touched.accountHolderName && errors.accountHolderName}
        />

        <TextField
          fullWidth
          name="accountNumber"
          label="Account Number"
          type="password"
          value={values.accountNumber}
          onChange={handleChange}
          error={touched.accountNumber && Boolean(errors.accountNumber)}
          helperText={touched.accountNumber && errors.accountNumber}
        />

        <TextField
          fullWidth
          name="confirmAccountNumber"
          label="Confirm Account Number"
          type="password"
          value={values.confirmAccountNumber}
          onChange={handleChange}
          error={touched.confirmAccountNumber && Boolean(errors.confirmAccountNumber)}
          helperText={touched.confirmAccountNumber && errors.confirmAccountNumber}
        />

        <TextField
          fullWidth
          name="routingNumber"
          label="Routing Number"
          value={values.routingNumber}
          onChange={handleChange}
          error={touched.routingNumber && Boolean(errors.routingNumber)}
          helperText={touched.routingNumber && errors.routingNumber}
        />

        <TextField
          fullWidth
          name="confirmRoutingNumber"
          label="Confirm Routing Number"
          value={values.confirmRoutingNumber}
          onChange={handleChange}
          error={touched.confirmRoutingNumber && Boolean(errors.confirmRoutingNumber)}
          helperText={touched.confirmRoutingNumber && errors.confirmRoutingNumber}
        />

        <TextField
          fullWidth
          name="billingZipCode"
          label="Billing Zip Code"
          value={values.billingZipCode}
          onChange={handleChange}
          error={touched.billingZipCode && Boolean(errors.billingZipCode)}
          helperText={touched.billingZipCode && errors.billingZipCode}
        />

        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSubmitting}
          sx={{
            mt: 2,
            bgcolor: '#ff4d4f',
            '&:hover': {
              bgcolor: '#ff7875',
            },
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

