import React from "react";
// Customizable Area Start
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import AddChild from "../../../components/src/AddChild";
import AddParent from "../../../components/src/AddParent";
import "@mui/styles";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";
import { Box, Container, Typography, Grid, styled, Divider, Drawer } from "@material-ui/core";
import { Alert, ProfileImg, imageArray, imageArray1, imageArray2, imageArray3 } from "./assets";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const drawerWidth = 850;

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    // height: 'auto',
    flexShrink: 0,
    borderRadius: '10px 0px 0px 10px'
  },
  ".menuButton": {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  ".toolbar": {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));


// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    return (
      <Box style={{ padding: "10px 25px" }}>
        <Box>
          <Typography style={Styles.mainText}>Hi {this.state?.getParentsUserData?.[0]?.attributes?.first_name},</Typography>
          <Typography style={Styles.childText}>Welcome to Recess! On this screen you can manage enrollment and or manage associated caretakers.</Typography>
        </Box>
        <Box style={{ margin: '30px 0px', backgroundColor: 'rgb(243 172 168)', display: 'flex', padding: '10px', borderRadius: '10px' }}>
          <img style={{ width: '24px', height: '24px', margin: "0px 10px" }} src={Alert} />
          <Typography style={Styles.child1Text}>Welcome to Recess! On this screen you can manage enrollment and or manage associated caretakers.</Typography>
        </Box>
        <Box style={{ margin: '50px 0px', padding: '10px' }}>
          <Typography style={Styles.Enrollment}>Enrollments</Typography>
          <Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', borderRadius: '15px 15px 0px 0px' }}>
              <Grid container spacing={4}>
                {this.state.getChildData?.data?.map((data: any) => (
                  <>
                    <Grid item lg={2} md={2} sm={2}>
                      <img style={{ width: '40px', height: '40px', margin: "0px 10px" }} src={data.attributes.child_profile_image ? data.attributes.child_profile_image : ProfileImg} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}><Typography style={{ textTransform: 'capitalize' as 'capitalize' }}>{data.attributes.first_name} {data.attributes.last_name}</Typography></Grid>
                    <Grid item lg={3} md={2} sm={2}><Typography>{data.attributes.gender === 'Male' ? 'Son' : data.attributes.gender === 'Female' ? 'Daughter' : ''}</Typography></Grid>
                    <Grid item lg={3} md={2} sm={2}><Typography>{this.calculateAge(data.attributes.date_of_birth)}</Typography></Grid>
                    <Grid item lg={1} md={2} sm={2}>
                      <ArrowForwardIosIcon style={{ cursor: 'pointer' }} onClick={() => this.toggleDrawer2(data)} />
                    </Grid>
                  </>
                ))}

              </Grid>

            </Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={Styles.box2_paper}>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={2} md={2} sm={2}>
                  <img style={Styles.profileImg} src={ProfileImg} />
                </Grid>
                <Grid item lg={10} md={10} sm={10} >
                  <Box data-test-id="toggleDrawer" onClick={(event) => this.toggleDrawer(event)} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                    <Typography style={Styles.EnrollChild}>Enroll Child</Typography>
                    <AddIcon style={{ margin: '3px 5px' }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>


          </Box>
        </Box>

        <Box style={{ margin: '50px 0px', padding: '10px' }}>
          <Typography style={Styles.Enrollment}>CareTakers</Typography>
          <Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', borderRadius: '15px 15px 0px 0px' }}>
              <Grid container spacing={4}>
                {this.state.getParantData.data && this.state.getParantData.data.length > 0 ?
                  this.state.getParantData?.data?.map((data: any) => (
                    <React.Fragment>
                      <Grid item lg={1} md={1} sm={2}>
                        <img style={{ width: '40px', height: '40px', margin: "0px 10px" }} src={data.attributes.image ? data.attributes.image : ProfileImg} />
                      </Grid>
                      <Grid item lg={2} md={2} sm={3}><Typography style={{ textTransform: 'capitalize' as 'capitalize' }}>{data.attributes.first_name} {data.attributes.last_name}</Typography></Grid>
                      <Grid item lg={2} md={2} sm={2}><Typography>{data.attributes.designation}</Typography></Grid>
                      <Grid item lg={4} md={4} sm={2}><Typography>{data.attributes.email}</Typography></Grid>
                      <Grid item lg={2} md={2} sm={2}><Typography>{data.attributes.phone_number}</Typography></Grid>
                      <Grid item lg={1} md={1} sm={2}>
                        <ArrowForwardIosIcon style={{ cursor: 'pointer' }} />
                      </Grid>
                    </React.Fragment>
                  ))
                  :
                  <Typography>No data </Typography>
                }

              </Grid>

            </Box>
            <Box boxShadow={3}
              bgcolor="background.paper"
              style={{ padding: '20px', display: 'flex', backgroundColor: 'F1F1F1', borderRadius: '0px 0px 15px 15px' }}>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={2} md={2} sm={2}>
                  <img style={{ width: '40px', height: '40px', margin: "0px 10px" }} src={ProfileImg} />
                </Grid>
                <Grid onClick={(event) => this.toggleDrawerParent(event)} item lg={10} md={10} sm={10} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <Typography style={Styles.EnrollChild}>Add CareTakers</Typography>
                  <AddIcon style={{ margin: '3px 5px' }} />
                </Grid>
              </Grid>
            </Box>


          </Box>
        </Box>
        <CustomDrawer
          data-test-id="CustomDrawerCom"
          anchor="right"
          open={this.state.open}
          onClose={this.toggleDrawer}
        >
          <div />
          <Divider />
          <AddChild data-test-id="CustomDrawer" getChildProfileData={this.getChildProfileData} postVaccinationUploadData={this.postVaccinationUploadData} postChildSolidPureesData={this.postChildSolidPureesData} postNapsTimingData={this.postNapsTimingData} selected={this.state.selected} handleButtonClick={this.handleButtonClick} getAllSolidandPureesData={this.getAllSolidandPureesData} getAllNapsData={this.getAllNapsData} getPottyTrainingData={this.getPottyTrainingData} get_allergenProfileData={this.get_allergenProfileData} postNotesgData={this.postNotesgData} UpDateChildProfile={this.UpDateChildProfile} toggleDrawer2={this.state.toggleDrawer2} open2={this.state.open2} postPottyTrainingData={this.postPottyTrainingData} imageSelect={this.state.imageSelect} handleBoxClick={this.postAllergendData} postEnrollChildData={this.postEnrollChildData} handleChange={this.handleFormChange} handleFormChange2={this.handleFormChange2} profileImageEdit={this.state.profileImageEdit} formData={this.state.formData} imageArray={this.state.allergen} imageArray1={this.state.pottyTraining} imageArray2={this.state.getAllNap} imageArray3={this.state.getSolidandPurees} DrawerClose={this.DrawerClose} />
        </CustomDrawer>
        <CustomDrawer
          data-test-id="CustomDrawerCom"
          anchor="right"
          open={this.state.openParent}
          onClose={this.toggleDrawerParent}
        >
          <div />
          <Divider />
          <AddParent data-test-id="CustomDrawer" handleCloseDialog={this.handleCloseDialog} InviteSuccess={this.state.InviteSuccess} selected={this.state.selected} handleButtonClick={this.handleButtonClick} getAllSolidandPureesData={this.getAllSolidandPureesData} getAllNapsData={this.getAllNapsData} getPottyTrainingData={this.getPottyTrainingData} get_allergenProfileData={this.get_allergenProfileData} postNotesgData={this.postNotesgData} UpDateChildProfile={this.UpDateChildProfile} toggleDrawer2={this.state.toggleDrawer2} open2={this.state.open2} postPottyTrainingData={this.postPottyTrainingData} imageSelect={this.state.imageSelect} handleBoxClick={this.postAllergendData} postEnrollChildData={this.parantInvite} handleChange={this.handleFormChangeParant} handleFormChange2={this.handleFormChange2} profileImageEdit={this.state.profileImageEdit} formData={this.state.formDataParant} imageArray={this.state.allergen} imageArray1={this.state.pottyTraining} imageArray2={this.state.getAllNap} imageArray3={this.state.getSolidandPurees} DrawerClose={this.toggleDrawerParent} />
        </CustomDrawer>

      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = {
  mainText: {
    fontSize: '40px',
    fontWeight: 600,
    textTransform: 'capitalize' as 'capitalize',
  },
  childText: {
    fontSize: '16px',
    fontWeight: 400
  },
  child1Text: {
    fontSize: '16px',
    fontWeight: 400
  },
  Enrollment: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '15px',
  },
  EnrollChild: {
    display: 'flex'
  },
  box2_paper: {
    padding: '20px',
    display: 'flex',
    backgroundColor: 'F1F1F1',
    borderRadius: '0px 0px 15px 15px'
  },
  profileImg: {
    width: '40px',
    height: '40px',
    margin: "0px 10px"
  }
}
// Customizable Area End
