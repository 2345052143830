
import React from "react";
// Customizable Area Start
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { SideBar } from "../../../components/src/SideBar";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DashbordIncident extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  IncidentRow = ({ data, index }: {
    data: { avatar: string, name: string, date: string, type: string, location: string, age: string | number, injury: string }, index: number
  }) => (
    <TableRow
      key={index}
      style={{
        backgroundColor: index % 2 === 0 ? "#fde2e4" : "#ffffff",
      }}
    >
      <TableCell>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              background: "red",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          ></div>
          <Avatar
            src={data.avatar}
            alt={data.name}
            style={{ marginRight: "10px" }}
          />
          {data.name}
        </div>
      </TableCell>
      <TableCell>{data.age} Yrs</TableCell>
      <TableCell>{data.date}</TableCell>
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.injury}</TableCell>
      <TableCell>{data.location}</TableCell>
      <TableCell>
        <IconButton>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start

    const incidentData = [
      {
        name: "Amy Roberts",
        avatar: "https://via.placeholder.com/40",
        age: 12,
        date: "1/25/24",
        type: "Behavioral",
        injury: "No",
        location: "Fishtown",
      },
      {
        name: "John Doe",
        avatar: "https://via.placeholder.com/40",
        age: 10,
        date: "1/25/24",
        type: "Behavioral",
        injury: "No",
        location: "Fishtown",
      },
    ];

    return (
      //Merge Engine DefaultContainer
      <Box width={"100vw"} height={"100vh"}>
        <SideBar selectedPage="INCIDENTS"
          navigateFun={this.navigateFun}
          SideBarContent={<></>}
          locations={this.state.locations || []}
          HeaderTitel={<Typography
            variant="h6"
            fontWeight="semibold"
            fontFamily={"Inter"}
            fontSize={"28px"}
          >
            {this.formatDate(Date.now())}
          </Typography>
          }
          NavigationFunction={this.props.navigation.navigation}
          updateLocationState={this.updateLocationState}
        >
          <div style={{ padding: "20px" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold", marginBottom: "16px" }}
            >
              Incident Report
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ marginBottom: "30px" }}>
                  <TableRow style={{ backgroundColor: "#f8d7da", width: "100%" }}>
                    {[
                      "Supervisor Status",
                      "Age",
                      "Date of Incident",
                      "Type of Incident",
                      "Injury",
                      "Location",
                      "",
                    ].map((header, index) => (
                      <TableCell
                        key={index}
                        style={{ fontWeight: "bold", fontSize: 16 }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {incidentData.map((data, index) => (
                    this.IncidentRow({ data, index })
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </SideBar>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
