import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  styled
} from "@material-ui/core";
import { backgroundImage, logo, WelcomeImg } from "../../email-account-login/src/assets";
import "./WelcomeScreen.css";

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
  },
});
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    {/* Customizable Area Start */ }
    return (
      <Box style={Styles.mainBox1}>
        <Box style={Styles.LoginLogo1}>
          <Box style={Styles.logoBox1}>
            <img style={Styles.logo1} src={logo} />
          </Box>
          <img src={WelcomeImg} style={Styles.welcomeLogo1} />
          <Box style={Styles.buttonBox1}>
            <CustomTextField style={Styles.textField1} data-test-id = "onEmailValue" value={this.state.email} onChange={this.onEmailValue} id="outlined-basic" label="Email Address" variant="outlined" />
            <Button style={Styles.button} data-test-id = "createuser" disabled={!this.state.email || this.state.email === ""} onClick={this.createAccount}>Create New Account</Button>
            <Button data-test-id="goToLoginScreen" onClick={this.goToLoginScreen} style={Styles.button}>Login</Button>
          </Box>
        </Box>
      </Box>
    );
    {/* Customizable Area End */ }
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const Styles = {
  // Customizable Area Start
    mainBox1: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex' as 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px'

    },
    LoginLogo1: {
        width: '390px',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0px 55px 33px 55px',
    },
    logoBox1: {
        padding: '0px 30px'
    },
    logo1: {
        width: '100%'
    },
    welcomeLogo1: {
        margin: '30px auto',
        display: 'flex'
    },
    buttonBox1: {
        display: 'grid',
        gap: '25px'
    },
    button: {
        width: '100%',
        height: '60px',
        backgroundColor: 'F1544B',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600
    },
    button3: {
        width: '100%',
        height: '60px',
        color: '#F1544B',
        fontSize: '16px',
        fontWeight: 600,
        transition: 'none',
        transform: 'none'
    },
    textField1: {
        width: "85%",
        margin: "auto"
    }
  // Customizable Area End
};
